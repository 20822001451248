import axios from "axios";
import unAuthUser from "./unAuthUser";

export default async function postman(url, data, user_token = null, multipart_form_data = null) {

	let headers;
	if (user_token) {
		if (!multipart_form_data) {
			headers = {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${user_token}`
			}
		} else {
			headers = {
				'Content-Type': 'multipart/form-data',
				'Authorization': `Bearer ${user_token}`
			}
		}
	} else {
		headers = {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	}

	let postman = await axios.post(
		url,
		data,
		{
			headers: headers
		}
	).catch(error => {
		console.log(error.message)
	});

	if (postman.data) {

		if (postman.data?.error && postman.data.error?.message) {
			if (postman.data.error.code === 0 && postman.data.error.message == 'You are not authorized!') {
				unAuthUser();
			}
		}

		let response = postman.data;

		return response;
	}

	return null;
}
