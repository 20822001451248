<template>
	<!-- invest -->
	<div class="modal fade" id="modalInvest">
		<div class="modal-dialog modal-dialog_md">
			<div class="modal__content">
				<form action="javascript:;">
					<div class="tab-section">

						<div class="tab-content"
							v-if="!$store.getters.USER_NAME || !$store.getters.USER_SURNAME || !$store.getters.USER_COUNTRY || blackListCountries()">
							<div class="tab-pane active">
								<div class="modal__header text_mediumbold text_upper">
									Additional data
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click.prevent="closeBalancePopUp();"></button>
								</div>

								<div class="modal__body modal__body_padding">
									<form action="javascript:;" style="position: relative;">
										<AdditionalData />
									</form>
								</div>
							</div>
						</div>

						<div class="tab-content" v-else>

							<!-- step-1 -->
							<div class="tab-pane" :class="{ 'active': step === 1 }" v-if="step === 1">
								<div class="modal__header text_mediumbold text_upper">
									INVEST (STEP 1)
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click="closeInvest();"></button>
								</div>
								<div class="modal__body modal__body_padding-sm">
									<div class="mb-xxs text_normal text_size-sm text_dark">
										How much do you want to invest in trader "<span class="text_primary">{{
											$store.getters.GET_TRADER.login }}</span>"?
									</div>
									<input class="text_size-md form-control text_center" type="number"
										v-model="amount_invets" step="10" min="10" max="100000"
										@click.prevent="investError = ''">

									<div v-if="investError != ''" class="invalid_input">{{ investError }}</div>

									<div class="form-group text_center mt-sm">
										<a href="javascript:;" class="btn btn_size-sm w-100 tab-link"
											@click.prevent="toStep(2)">Next</a>
									</div>
								</div>
							</div>
							<!-- /step-1 -->

							<!-- step-2 -->
							<div class="tab-pane" :class="{ 'active': step === 2 }" v-if="step === 2">
								<div class="modal__header text_mediumbold text_upper">
									INVEST (STEP 2)
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click="closeInvest();"></button>
								</div>

								<div class="modal__body modal__body_padding">
									<div class="text_dark text_normal text_size-sm mb-xs">Select way of payment</div>

									<div class="radio-list radio-list_clear">
										<label class="radio-list__item w-100" :class="{ 'active': payment_method == 1 }">
											<input type="radio" class="collapse" name="method" value="1"
												v-model="payment_method">
											<div class="radio-list__icon icon icon-pay-bank"></div>
											<div class="radio-list__text">Bank transfer</div>
										</label>
										<label class="radio-list__item w-100" :class="{ 'active': payment_method == 2 }">
											<input type="radio" class="collapse" name="method" value="2"
												v-model="payment_method">
											<div class="radio-list__icon icon icon-pay-bitcoin"></div>
											<div class="radio-list__text">Crypto transfer</div>
										</label>
										<label class="radio-list__item w-100"
											:class="{ 'active': payment_method == 3, 'disabled-label': $store.getters.BALANCE.active < amount_invets }">
											<input type="radio" class="collapse" name="method" value="3"
												v-model="payment_method"
												:disabled="$store.getters.BALANCE.active < amount_invets">
											<div class="radio-list__icon icon icon-pay-wallet"></div>
											<div class="radio-list__text">Balance
												<span class="radio-list__value">{{ $store.getters.BALANCE.active }} $</span>
											</div>
										</label>
									</div>

									<div class="form-group text_center d-flex btn-group">
										<a href="javascript:;" class="btn btn_light btn_size-sm w-100 tab-link"
											@click.prevent="toStep(1)">Back</a>

										<a href="javascript:;" class="btn btn_size-sm w-100 tab-link"
											@click.prevent="toStep(3)">Next</a>
									</div>
								</div>

							</div>
							<!-- /step-2 -->

							<!-- select crypto -->
							<div class="tab-pane" :class="{ 'active': step === 789 }" v-if="step === 789">
								<div class="modal__header text_mediumbold text_upper">
									INVEST (STEP 2)
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click="closeInvest();"></button>
								</div>

								<div class="modal__body modal__body_padding" style="position: relative;">
									<loading :active="waitApiPayment" :is-full-page="false" color="#583afb"
										loader="spinner">
									</loading>

									<div class="text_dark text_normal text_size-sm mb-xs">Select way of payment</div>
									<div class="radio-list radio-list_clear">
										<label class="radio-list__item"
											:class="{ 'active': selectedPaymentMethod === 'btc' }">
											<input v-model="selectedPaymentMethod" type="radio" class="collapse" value="btc"
												name="payment">
											<div class="radio-list__icon icon icon-pay-bitcoin"></div>
											<div class="radio-list__text">Bitcoin</div>
										</label>
										<label class="radio-list__item"
											:class="{ 'active': selectedPaymentMethod === 'eth' }">
											<input v-model="selectedPaymentMethod" type="radio" class="collapse" value="eth"
												name="payment">
											<div class="radio-list__icon icon icon-pay-ethereum"></div>
											<div class="radio-list__text">Ethereum</div>
										</label>
										<label class="radio-list__item w-100"
											:class="{ 'active': selectedPaymentMethod === 'tusdt' }">
											<input v-model="selectedPaymentMethod" type="radio" class="collapse"
												value="tusdt" name="payment">
											<div class="radio-list__icon icon icon-pay-tether"></div>
											<div class="radio-list__text">Tether USDT</div>
										</label>
									</div>

									<div class="form-group text_center d-flex btn-group">
										<a href="javascript:;" class="btn btn_light btn_size-sm w-100 tab-link"
											@click.prevent="toStep(2)">Back</a>

										<a href="javascript:;" class="btn btn_size-sm w-100 tab-link"
											@click.prevent="toStep(987)">Next</a>
									</div>
								</div>

							</div>
							<!-- /select crypto -->

							<!-- paymentInfo -->
							<div class="tab-pane" :class="{ 'active': step === 888 }">
								<div class="modal__header text_mediumbold text_upper">
									Invest (step 3)
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click.prevent="closeBalancePopUp();"></button>
								</div>
								<div class="modal__body modal__body_padding-sm">
									<form action="javascript:;">
										<div style="margin-bottom: 14px;">
											<div class="mb-xxs text_normal text_size-sm"
												style="display: flex; align-items: center;">

												<span v-if="selectedPaymentMethod == 'btc'" class="crypto-name">
													<img class="minCryptaIcon" src="@/assets/img/btc-min.png"> Bitcoin
												</span>

												<span v-if="selectedPaymentMethod == 'eth'" class="crypto-name">
													<img class="minCryptaIcon" src="@/assets/img/eth-min.png"> Ethereum
												</span>

												<span v-if="selectedPaymentMethod == 'tusdt'" class="crypto-name">
													<img class="minCryptaIcon" src="@/assets/img/tether-min.png"> Tether
													USDT
												</span>

												&nbsp;({{ infoApiPayment.pay_currency }})

											</div>
										</div>

										<ViewPayStatus v-if="infoApiPayment.payment_id"
											:paymentId="infoApiPayment.payment_id" :status="infoApiPayment.payment_status"
											:pay_amount="infoApiPayment.pay_amount"
											:pay_address="infoApiPayment.pay_address"
											:timer20min="infoApiPayment.timer20min" />
									</form>
								</div>
							</div>
							<!-- /paymentInfo -->

							<!-- step-3 -->
							<div class="tab-pane" :class="{ 'active': step === 3 }">
								<div class="modal__header text_mediumbold text_upper">
									INVEST (STEP 3)
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click="closeInvest();"></button>
								</div>
								<div class="modal__body modal__body_padding-sm">
									<!-- <div class="btn-list btn-list_radio d-flex flex-wrap">
										<label class="btn-list__item d-flex">
											<input type="radio" name="curr" class="collapse" checked>
											<span class="btn btn_transparent w-100">$</span>
										</label>
										<label class="btn-list__item d-flex">
											<input type="radio" name="curr" class="collapse">
											<span class="btn btn_transparent w-100">€</span>
										</label>
										<label class="btn-list__item d-flex">
											<input type="radio" name="curr" class="collapse">
											<span class="btn btn_transparent w-100">PGB</span>
										</label>
									</div> -->
									<div class="text_dark text_normal text_size-sm mb-xxs">Select Currency</div>
									<div class="form-group_sm">
										<FakeSelectCurrencies type="in" @item-selected="handleItemSelected" />
									</div>

									<div v-for="(c, i) in selectItemCurr.rows">
										<div class="mb-xxs text_dark text_normal text_size-sm">{{ c.name }}</div>
										<div class="form-group_sm form-wrap">
											<input class="form-control form-control_xs" :value="c.value"
												@click.prevent="copyToClipboard(c.value)" readonly>
											<a href="javascript:;" class="copy-link icon form-icon_right icon-doc"
												@click.prevent="copyToClipboard(c.value)"></a>
										</div>
									</div>
									<div>
										<div class="mb-xxs text_dark text_normal text_size-sm">Reference</div>
										<div class="form-group_sm form-wrap">
											<input class="form-control form-control_xs" :value="$store.getters.USER_ID"
												@click.prevent="copyToClipboard($store.getters.USER_ID)" readonly>
											<a href="javascript:;" class="copy-link icon form-icon_right icon-doc"
												@click.prevent="copyToClipboard($store.getters.USER_ID)"></a>
										</div>
									</div>

									<!-- <div class="mb-xxs text_dark text_normal text_size-sm">IBAN</div>
									<div class="form-group_sm form-wrap">
										<input class="form-control form-control_xs" id="iban">
										<a href="#iban" class="copy-link icon form-icon_right icon-doc"></a>
									</div>
									<div class="mb-xxs text_dark text_normal text_size-sm">REFERENCE</div>
									<div class="mb-sm"><textarea class="form-control form-control_xs"></textarea></div> -->

									<div class="form-group text_center d-flex btn-group">
										<a href="javascript:;" class="btn btn_light btn_size-sm w-100 tab-link"
											@click.prevent="toStep(2)">Back</a>
										<a href="javascript:;" class="btn btn_size-sm w-100 modal-open"
											@click.prevent="ipaidGo();">I PAID</a>
									</div>
								</div>
							</div>
							<!-- /step-3 -->

							<!-- завершение баланса инвестиций -->
							<div class="tab-pane" :class="{ 'active': step === 999 }">
								<div class="modal__header text_mediumbold text_upper">

									<span v-if="investment.wait_api === 1">Processing...</span>
									<span v-if="investment.wait_api === 0">
										<span v-if="investment.not_invested === 0">Completed</span>
										<span v-if="investment.not_invested === 1">Error</span>
									</span>

									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click="closeInvest();"></button>
								</div>

								<div class="modal__body modal__body_padding-sm" v-if="investment.wait_api === 0">
									<div class="d-flex text_size-sm mb-sm" v-if="investment.not_invested === 0">
										<span class="icon icon-business me-1"></span>
										<div class="text_transparent">
											You have successfully invested
											<span class="text_primary">{{ amount_invets }}$</span> in
											<span class="text_primary">{{ $store.getters.GET_TRADER.login }}</span> from
											your balance!
										</div>
									</div>

									<div class="d-flex text_size-sm mb-sm" v-if="investment.not_invested === 1">
										{{ investment.error }}
									</div>

									<div class="form-group text_center">
										<a href="javascript:;" class="btn btn_size-sm modal-close"
											@click="closeInvest();">OK</a>
									</div>
								</div>
								<div class="modal__body modal__body_padding-sm" v-else>
									<div style="text-align: center; padding: 20px; position: relative;">
										<loading :active="true" :is-full-page="false" color="#583afb" loader="dots">
										</loading>
									</div>
								</div>
							</div>
							<!-- /завершение баланса инвестиций -->

							<!-- завершение перевода инвестиций -->
							<div class="tab-pane" :class="{ 'active': step === 998 }">
								<div class="modal__header text_mediumbold text_upper">

									<span v-if="ipaid.wait_api === 1">Processing...</span>
									<span v-if="ipaid.wait_api === 0">
										<span v-if="ipaid.not_success === 0">Pending</span>
										<span v-if="ipaid.not_success === 1">Error</span>
									</span>

									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click="closeInvest();"></button>
								</div>

								<div class="modal__body modal__body_padding-sm" v-if="ipaid.wait_api === 0">
									<div class="d-flex text_size-sm mb-sm" v-if="ipaid.not_success === 0">
										<span class="icon icon-business me-1"></span>
										<div class="text_transparent">
											The transfer will be credited manually within 1-3 days
										</div>
									</div>

									<div class="d-flex text_size-sm mb-sm" v-if="ipaid.not_success === 1">
										{{ ipaid.error }}
									</div>

									<div class="form-group text_center">
										<a href="javascript:;" class="btn btn_size-sm modal-close"
											@click="closeInvest();">OK</a>
									</div>
								</div>
								<div class="modal__body modal__body_padding-sm" v-else>
									<div style="text-align: center; padding: 20px; position: relative;">
										<loading :active="true" :is-full-page="false" color="#583afb" loader="dots">
										</loading>
									</div>
								</div>
							</div>
							<!-- /завершение перевода инвестиций -->


						</div>
						<div class="modal__footer text_dark text_size-sm" v-if="(step != 999 && step != 998) && $store.getters.USER_NAME && $store.getters.USER_SURNAME && $store.getters.USER_COUNTRY && blackListCountries() === false">
							<p>Depending on your bank, funds will be credited within 1-3 business days</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
	<!-- /invest -->
</template>
  

<script>
import postman from '@/functions/postman';
import modalFunction from '@/functions/modal';

import noUiSlider from '@/assets/js/nouislider.min';
import '@/assets/css/nouislider.min.css';
import '@/assets/css/swiper-bundle.min.css';

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

import FakeSelectCurrencies from '@/components/FakeSelectCurrencies.vue';

import ViewPayStatus from '@/components/ViewPayStatus.vue';

import AdditionalData from '@/components/cabinet/AdditionalData.vue';
import blackListCountries from '@/functions/blackListCountries';

export default {
	components: {
		Loading,
		FakeSelectCurrencies,
		ViewPayStatus,
		AdditionalData
	},

	data: () => ({
		modalFunction,
		blackListCountries,

		Slider: null,
		Slider_start: 1000,

		step: 1,
		amount_invets: 1000,
		investError: '',
		payment_method: 1,

		investment: {
			not_invested: 0,
			wait_api: 0,
			error: ''
		},

		ipaid: {
			invest_trader: 0,
			currenc: null,
			amount: 0,
			request_type: 'in',
			wait_api: 0,
			not_success: 0,
			error: ''
		},

		currencies: {},
		selectItemCurr: {},

		selectedPaymentMethod: 'btc',
		waitApiPayment: false,
		infoApiPayment: {},
	}),

	mounted: function () {
		// let ref_slider = this.$refs.slider_input;
		// if (ref_slider) {
		// 	this.Slider = ref_slider;

		// 	noUiSlider.create(this.Slider, {
		// 		connect: [true, false],
		// 		range: {
		// 			'min': 10,
		// 			'max': 10000
		// 		},
		// 		start: [this.Slider_start],
		// 		step: 10
		// 	});

		// 	this.Slider.noUiSlider.on('update', (values, handle) => {
		// 		let newValue = Math.round(values[0]);
		// 		this.Slider_start = newValue;
		// 	});
		// }
	},

	methods: {
		// chSliderStart: function () {
		// 	this.Slider.noUiSlider.set(this.Slider_start);
		// }

		closeBalancePopUp() {
			modalFunction('close', null);
			this.step = 1;
			this.selectedPaymentMethod = 'btc';
			this.waitApiPayment = false;
			this.infoApiPayment = {};
		},

		async ipaidGo() {
			this.ipaid.not_success = 0;
			this.ipaid.error = '';
			this.ipaid.wait_api = 1;
			this.step = 998;

			this.ipaid.currenc = this.selectItemCurr;
			this.ipaid.amount = this.amount_invets;
			this.ipaid.invest_trader = this.$store.getters.GET_TRADER.id;

			let post = await postman(
				`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/request_in.php`,
				this.ipaid,
				this.$store.getters.AUTH_TOKEN
			);

			if (post.error_input) {
				this.ipaid.not_success = 1;
				this.ipaid.error = post.error_input.info;
			} else {
				if (post.status) {
					this.ipaid.not_success = 0;
				}
			}

			this.ipaid.wait_api = 0;
		},

		copyToClipboard(value) {
			navigator.clipboard.writeText(value)
				.then(() => {
					console.log('Text copied to clipboard')
				})
				.catch(err => {
					console.error('Error copying text: ', err)
				})
		},

		handleItemSelected(item) {
			this.selectItemCurr = item;
		},

		async investments() {
			const trader_id = this.$store.getters.GET_TRADER.id;
			if (trader_id < 1) {
				this.investment.not_invested = 1;
				this.investment.error = 'ID трейдера не верный';
				this.investment.wait_api = 0;
				return false;
			}

			const amount = this.amount_invets;
			if (amount < 1) {
				this.investment.not_invested = 1;
				this.investment.error = 'Сумма инвестиции должна быть больше 10$';
				this.investment.wait_api = 0;
				return false;
			}

			this.investment.wait_api = 1;

			let post = await postman(
				`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/investments/new.php`,
				{
					trader_id: trader_id,
					amount: amount
				},
				this.$store.getters.AUTH_TOKEN
			);

			if (post.error_input) {
				this.investment.not_invested = 1;
				this.investment.error = post.error_input.info;
			} else {
				if (post.status) {
					this.$store.dispatch('getBalance');
					this.investment.not_invested = 0;
				}
			}

			this.investment.wait_api = 0;
		},

		closeInvest() {
			this.step = 1;
			this.amount_invets = 1000;
			this.investError = '';
			this.payment_method = 1;

			this.investment.wait_api = 0;
			this.investment.not_invested = 0;
			this.investment.error = '';

			modalFunction('close', null);
		},

		async toStep(number) {
			if (number == 1) {
				this.payment_method = 1;
			}

			if (number === 2) {
				if (this.amount_invets < 10) {
					this.investError = 'Minimum amount is 10$';
					return false;
				}

				if (this.amount_invets > 100000) {
					this.investError = 'Maximum amount for 1 transaction is 100 000$';
					return true;
				}
			}

			if (number == 987) {
				this.waitApiPayment = true;

				let post = await postman(
					`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/crypto-payments/create.php`,
					{
						invest_trader: this.$store.getters.GET_TRADER.id,
						amount: this.amount_invets,
						selectedPaymentMethod: this.selectedPaymentMethod
					},
					this.$store.getters.AUTH_TOKEN
				);

				if (post?.status && post.status === true) {
					this.step = 888;
					this.infoApiPayment = post.payment;
					this.waitApiPayment = false;
				} else {
					this.waitApiPayment = false;
					if (post.error) alert(post.error);
				}

				return true;
			}

			if (number === 3) {
				if (this.payment_method == 1) { //bank transfer

				}

				if (this.payment_method == 2) { //crypto
					this.step = 789;
					return false;
				}

				if (this.payment_method == 3) { //balance
					this.investment.wait_api = 1;
					this.step = 999;

					await this.investments();

					return true;
				}
			}

			this.step = number;
		}
	}
}
</script>
