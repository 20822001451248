export default {
	state: {
		trader: {}
	},
	getters: {
		GET_TRADER: state => {
			return state.trader;
		}
	},
	mutations: {
		setTraderData(state, data) {
			state.trader = data;
		}
	},
	actions: {
	}
}
