export default {
	state: {
		iam: {},
		_loadIamData: false
	},
	getters: {
		LOAD_IAM_DATA: state => {
			return state._loadIamData;
		}
	},
	mutations: {
		setIamData(state, data) {

			if (!state._loadIamData) {
				state._loadIamData = true;
			}
		}
	},
	actions: {
	}
}
