<template>
	<!-- <div class="fake-select dropdown" :class="{ open: isOpen }" @click="toggleSelect">
		<div class="fake-select__item" v-for="( item, index ) in  items" :key="index" @click="selectItem(item)"
			:class="{ active: item === selectedItem }">
			{{ item }}
		</div>
		<div class="fake-select__value" v-html="selectedItem"></div>
	</div> -->

	<div class="fake-select dropdown" ref="FKSC">
		<a href="javascript:;" v-if="selectedItem.name" @click="openFKS()"
			class="fake-select__link2 dropdown-toggle text_size-sm text_normal form-control form-control_clear">
			<span class="fake-select__value">
				<div class="d-flex align-items-center">
					<span class="me-1 currency-icon">
						<img :src="'https://api.mytraders.io/api-1.0/user/currencies_icons/' + selectedItem.icon"
							class="img-fluid">
					</span>
					{{ selectedItem.name }}
				</div>
			</span>
		</a>

		<div class="fake-select__dropdown dropdown-menu">
			<label class="fake-select__item" v-for="(c, i) in items" :key="i" @click="selectCurr(c)">
				<input type="radio" name="currency" checked class="collapse">
				<div class="d-flex align-items-center">
					<span class="me-1 currency-icon">
						<img :src="'https://api.mytraders.io/api-1.0/user/currencies_icons/' + c.icon" class="img-fluid">
					</span>
					{{ c.name }}
				</div>
			</label>
		</div>
	</div>
</template>
  
<script>
import postman from '@/functions/postman';

export default {
	props: {
		type: {
			type: String,
			required: true,
		},
		systemType: {
			type: String,
			required: false
		}
	},

	data() {
		return {
			items: {},
			isOpen: false,
			selectedItem: {},
		};
	},
	methods: {
		selectCurr(c) {
			this.selectedItem = c;
			this.$refs['FKSC'].classList.remove('open');
			this.$emit('item-selected', c);
		},

		openFKS() {
			if (!this.$refs['FKSC'].classList.contains('open')) {
				this.$refs['FKSC'].classList.add('open');
			}
		},

		getCurrencies: async function () {
			let post = await postman(
				`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/currencies.php`,
				{
					uuid: new Date(),
					type: this.type,
					systemType: this.systemType
				},
				this.$store.getters.AUTH_TOKEN
			);
			if (post?.data) {
				this.items = post.data;

				this.selectedItem = this.items.find(item => item.selected === true);
				this.$emit('item-selected', this.selectedItem);
			}
		}
	},
	mounted() {
		this.getCurrencies();
	}
};
</script>
