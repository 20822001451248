<template>
	<aside class="aside pane pane_left" :class="{ show: $store.getters.MOBILE_MENU }">

		<router-link to="/dashboard" class="logo aside__logo d-none d-xl-flex align-items-center mb-md">
			<div class="logo__image"><img src="@/assets/img/logo.svg" alt="" class="img-fluid"></div>
			<div class="logo__text"><img src="@/assets/img/logo-text.svg" alt="" class="img-fluid"></div>
		</router-link>

		<div class="user-info d-flex align-items-center d-xl-none justify-content-between">
			<a href="javascript:void(0);" class="d-flex align-items-center user-info__dropdown-toggle modal-open">
				<div class="user-info__image"><img :src="$store.getters.USER_AVATAR" alt=""></div>
				<div class="user-info__text">
					<div class="text_normal user-info__name text_size-sm">{{ $store.getters.USER_NAME_HEADER }}</div>
					<div class="text_primary text_mediumbold">{{ $store.getters.BALANCE.full }} $</div>
				</div>
			</a>
			<a href="javascript:;" class="btn btn_size-xs modal-open"
				@click.prevent="modalFunction($event, 'open', 'modalBalance')">Top Up</a>
		</div>

		<ul class="menu-list mb-md">
			<router-link v-for="(link, index) in navigations" :key="index" tag="li" :to="link.to" :exact="link.exact"
				active-class="active" class="menu-list__item" v-slot="{ isActive, isExactActive }">
				<a :href="link.to" class="menu-list__link" :class="{ 'active': isActive }">
					<span class="icon menu-list__icon" :class="link.icon"></span> {{ link.title }}
				</a>
			</router-link>

			<li class="menu-list__item">
				<a href="javascript:;" class="menu-list__link" @click.prevent="modalFunction($event, 'open', 'newSupportTicket')">
					<span class="icon menu-list__icon icon-chat"></span> Support
				</a>
			</li>

			<li class="menu-list__item" v-if="!$store.getters.AUTH_TOKEN">
				<a href="javascript:;" class="menu-list__link" @click.prevent="modalFunction($event, 'open', 'login')">
					<span class="icon menu-list__icon icon-off"></span> Log In
				</a>
			</li>

			<router-link to="/exit" class="menu-list__item" v-if="$store.getters.AUTH_TOKEN">
				<a href="/exit" class="menu-list__link">
					<span class="icon menu-list__icon icon-off"></span> Log Out
				</a>
			</router-link>
		</ul>

		<div class="copyright aside__copyright text_size-sm">
			<div class="text_normal">Copyright © {{ new Date().getFullYear() }}</div>
			<div class="text_dark">All rights reserved</div>
		</div>
	</aside>
</template>

<script>
import modalFunction from '@/functions/modal';
import menuMixin from '@/mixins/menu.js';

export default {
	mixins: [menuMixin],
	data: () => ({
		modalFunction
	})
}
</script>
