<template>
	<component :is="layout">
		<router-view />
	</component>
</template>

<script>
import Unauthorized from '@/layouts/unauthorized/Unauthorized.vue';
import Cabinet from '@/layouts/cabinet/Cabinet.vue';

export default {
    computed: {
        layout() {
            return this.$route.meta.layout || "default-layout"
        }
    },

    components: {
        Unauthorized, Cabinet
    },

    beforeUnmount() {
        // console.log('beforeUnmount vue.app')
    },

    mounted() {
        this.loadZendeskWidget();
    },

    methods: {
        loadZendeskWidget() {
            const id = "ze-snippet";
            if (document.getElementById(id)) {
                return; 
            }

            const script = document.createElement("script");
            script.id = id;
            script.src = "https://static.zdassets.com/ekr/snippet.js?key=c4972b79-f84c-49a9-b806-7ac961c64b92";
            script.async = true;
            document.head.appendChild(script);
        }
    },

    data: () => ({
        closeDropdownsListener: null,
        openDropdownListener: null
    })
}
</script>

<style lang="scss">
@import '@/assets/css/style.css';
</style>
