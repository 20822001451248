export default {
	data: () => ({
		navigations: [
			{
				title: 'Dashbord',
				to: '/dashboard',
				icon: 'icon-widget',
				exact: true
			},
			{
				title: 'All Traders',
				to: '/traders',
				icon: 'icon-chart'
			},
			{
				title: 'Wallet',
				to: '/wallet',
				icon: 'icon-wallet'
			},
			{
				title: 'Security',
				to: '/security',
				icon: 'icon-shield'
			},
			{
				title: 'Referrals',
				to: '/referral',
				icon: 'icon-bag'
			},
			// {
			// 	title: 'Support',
			// 	to: '/support',
			// 	icon: 'icon-chat'
			// }
		]
	})
}
