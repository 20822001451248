<template>
	<!-- registration -->
	<div class="modal fade" id="registration">
		<div class="modal-dialog">
			<div class="modal__content">
				<div class="modal__header text_mediumbold text_upper">
					Sign up
					<button type="button" class="icon modal-close modal__close icon-close-circle"
						@click="modalFunction($event, 'close')"></button>
				</div>
				<div class="modal__body">
					<Form @submit="regFormHandler">
						<div class="form-group">
							<label class="form-label">
								<span class="icon icon-email-primary form-icon"></span>
								<Field name="email" @click="form.error.email = null" type="email" class="form-control"
									placeholder="E-mail" rules="required|email" :validateOnChange="true" />
							</label>
							<ErrorMessage class="invalid_input" name="email" />
							<div class="invalid_input" v-if="form.error.email">{{ form.error.email }}</div>
						</div>

						<div class="form-group">
							<label class="form-label">
								<span class="icon icon-lock-primary form-icon"></span>
								<Field name="password" @click="form.error.password = null" type="password"
									class="form-control" placeholder="Password" rules="required|min:8"
									:validateOnChange="true" />
							</label>
							<ErrorMessage class="invalid_input" name="password" />
							<div class="invalid_input" v-if="form.error.password">{{ form.error.password }}</div>
						</div>

						<div class="form-group">
							<div class="d-flex justify-content-start align-items-start">
								<input class="form-check-input" type="checkbox" id="terms-and-condition"
									v-model="form.terms_and_condition" @click="form.error.terms_and_condition = null" />
								<label class="form-check-label ms-rules" for="terms-and-condition"
									@click="form.error.terms_and_condition = null">
									I accept the <a href="/terms-of-use">Terms and Conditions</a> and <a
										href="/privacy-policy">Privacy Policy</a>
								</label>
							</div>
							<div class="invalid_input" v-if="form.error.terms_and_condition">{{
								form.error.terms_and_condition }}</div>
						</div>

						<div class="form-group">
							<button class="btn btn_size-sm-large w-100" type="submit" :disabled="form.wait_api === 1">
								Sign up
							</button>
						</div>

						<!-- Add "Or continue with" and Google and Apple buttons here -->
						<div class="form-group text-center">
							<p class="login-text">Or continue with</p>
							<div class="login-buttons">
								<GoogleLogin :callback="callbackGoogleAuth">
									<button type="button" class="login-btn google-login">
										<img src="@/assets/img/icons/google.svg" alt="Google" class="login-icon">Google
									</button>
								</GoogleLogin>
								<button class="login-btn apple-login" @click="loginWithApple">
									<img src="@/assets/img/icons/apple.svg" alt="Apple" class="login-icon">Apple
								</button>
							</div>
						</div>
					</Form>
				</div>
				<div class="modal__footer d-flex justify-content-between text_size-sm text_normal text_center_sm">
					<p class="text_dark">Already have an account?</p>
					<p><a href="#" class="modal-open" @click="modalFunction($event, 'open', 'login')">Log in</a></p>
				</div>
			</div>
		</div>
	</div>
	<!-- /registration -->
</template>

<script>
import modalFunction from "@/functions/modal";
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import { required, email, min } from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";

defineRule("required", required);
defineRule("min", min);
defineRule("email", email);

configure({
	generateMessage: localize("en", {
		messages: {
			required: "This field is required",
			min: "Minimum 8 characters",
			email: "Invalid email entered"
		}
	})
});

export default {
	components: {
		Form,
		Field,
		ErrorMessage
	},

	data: () => ({
		modalFunction,

		form: {
			error: {
				email: null,
				password: null,
				terms_and_condition: null
			},
			terms_and_condition: true, // Make the checkbox initially checked
			wait_api: 0
		},

		userTimeZone: 'Europe/London'
	}),

	methods: {
		getUserTimezone() {
			return Intl.DateTimeFormat().resolvedOptions().timeZone;
		},

		regFormHandler: async function (values) {
			let { email, password } = values;

			if (!this.form.terms_and_condition) {
				this.form.error.terms_and_condition = "You must agree to the terms";
				return false;
			}

			let postData = {
				email: email,
				password: password,
				type: "passive", // Always register as a Copy Trader
				id_parent: this.$store.getters.ID_PARENT,
				tz: this.userTimeZone
			};

			this.form.wait_api = 1;
			let response = await this.$store.dispatch("signUp", postData);
			if (response.error_input) {
				this.form.error[response.error_input.input] = response.error_input.info;
				this.form.wait_api = 0;
				return;
			}

			this.form.wait_api = 0;

			if (response._success) {
				this.modalFunction("", "close");
				this.$router.push("/dashboard");
				// location.reload();
			}
		},

		async callbackGoogleAuth(gresponse) {
			if (gresponse?.code) {
				let code = gresponse.code;

				this.form.wait_api = 1;

				let response = await this.$store.dispatch('signInViaGoogle', code);

				if (response.error_input) {
					this.form.error[response.error_input.input] = response.error_input.info;
					this.form.wait_api = 0;
					return
				}
				this.form.wait_api = 0;

				this.form.wait_api = 0;
				if (response._success) {
					this.modalFunction('', 'close');
					this.$router.push('/dashboard');
					// location.reload();
				}
			}
		},

		loginWithApple: function () {
			// Implement Apple login logic here
		}
	},

	mounted() {
		this.userTimeZone = this.getUserTimezone();
	}
};
</script>

<style>
.text-center {
	text-align: center;
	font-size: 14px;
	color: #2A3144;
}
</style>
