export default function generateTitle(to, pageTitle) {
    // Если pageTitle предоставлен, используйте его
    if (pageTitle) {
        return pageTitle;
    }

    const meta = to.meta;
    
    if (!meta?.titlePage) {
        return 'Copy trading service';
    }

    const pageName = meta.page_name;
    let count = 0;
    for (let key in to.params) {
        count++;
    }

    if (count === 0) {
        return meta.titlePage;
    }

    if (pageName === "Trader profile") {
        return meta.titlePage.replace('{login}', to.params.id);
    }

    if (pageName === "Support") {
        return meta.titlePage.replace('{id}', to.params.id);
    }
}
