import postman from "@/functions/postman"

export default {
	state: {
		bursers: [],
		defaultSortTraderView: 0
  	},

  	getters: {
		BURSES: state => {
			return state.bursers
		},
		DEFAULT_SORT_TRADERS_VIEW: state => {
			return state.defaultSortTraderView
		}
  	},

  	mutations: {
		set_burses(state, data) {
			state.bursers = data
		},
		set_default_sort_traders_view(state, data) {
			state.defaultSortTraderView = data
		}
  	},

	actions: {
		async get_burses( {commit} ) {
			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/burses/all.php`, {});
			if ( post.data ) {
				commit('set_burses', post.data )
			}

			return post;
		}
	}
}
