<template>
	<div style="position: relative" v-if="status == 'waiting'">
		<div class="viewLeftPayTimer" v-if="leftMinutes > 0 || leftSeconds > 0">
			{{ leftMinutesView }}:{{ leftSecondsView }}
		</div>
	</div>

	<div>
		<div class="mb-xxs text_dark text_normal text_size-sm">Wallet address :</div>
		<div class="form-group_sm form-wrap">
			<input style="font-size: 13px;" class="form-control form-control_xs" :value="pay_address" @click.prevent="copyToClipboard(pay_address)"
				readonly>
			<a href="javascript:;" class="copy-link icon form-icon_right icon-doc"
				@click.prevent="copyToClipboard(pay_address)"></a>
		</div>
	</div>

	<div>
		<div class="mb-xxs text_dark text_normal text_size-sm">Amount to transfer :</div>
		<div class="form-group_sm form-wrap">
			<input class="form-control form-control_xs" :value="pay_amount" @click.prevent="copyToClipboard(pay_amount)"
				readonly>
			<a href="javascript:;" class="copy-link icon form-icon_right icon-doc"
				@click.prevent="copyToClipboard(pay_amount)"></a>
		</div>
	</div>

	<div class="cmt-3">
		<div class="row">
			<div class="col-12">
				<div class="timeline-steps">
					<div class="timeline-step">
						<div class="timeline-content">
							<div class="inner-circle" :class="getClass(1)">
							</div>
							<p class="h6 text-muted mb-0 mb-lg-0">Waiting<br>for payment</p>
						</div>
					</div>
					<div class="timeline-step">
						<div class="timeline-content">
							<div class="inner-circle" :class="getClass(2)">
							</div>
							<p class="h6 text-muted mb-0 mb-lg-0">Processing<br>payment</p>
						</div>
					</div>
					<div class="timeline-step">
						<div class="timeline-content">
							<div class="inner-circle" :class="getClass(3)"></div>
							<p class="h6 text-muted mb-0 mb-lg-0">Your balance<br>topped up</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
	props: {
		status: {
			type: String,
			required: true
		},
		paymentId: {
			type: String,
			required: true
		},
		pay_address: {
			type: String,
			required: true
		},
		pay_amount: {
			type: Number,
			required: true
		},
		timer20min: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			selected: 0,
			timerId: null,

			leftMinutes: 0,
			leftSeconds: 0,
			leftMinutesView: '00',
			leftSecondsView: '00',
			timerLeftTime: null
		}
	},

	methods: {
		getClass(tab) {

			if (tab == 1) {
				if (this.status != 'waiting') {
					return 'success';
				}
			}

			if (tab == 2) {
				if (this.status == 'waiting') {
					return 'unactive';
				}

				if (this.status == 'finished') {
					return 'success';
				}
			}

			if (tab == 3) {
				if (this.status == 'waiting') {
					return 'unactive';
				}

				if (this.status != 'finished') {
					return 'unactive';
				}

				if (this.status == 'finished') {
					return 'success';
				}
			}
		},

		async checkStatus() {
			console.log('check status', this.paymentId)
		},

		copyToClipboard(value) {
			navigator.clipboard.writeText(value)
				.then(() => {
					console.log('Text copied to clipboard')
				})
				.catch(err => {
					console.error('Error copying text: ', err)
				})
		},

		calculateLeftTime() {
			let targetTime = new Date(this.timer20min).getTime();
			var currentTime = new Date().getTime();
			var timeDifference = targetTime - currentTime;

			// Расчет минут и секунд
			this.leftMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
			this.leftSeconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

			this.leftMinutesView = this.leftMinutes < 10 ? "0" + this.leftMinutes : this.leftMinutes;
			this.leftSecondsView = this.leftSeconds < 10 ? "0" + this.leftSeconds : this.leftSeconds;

			// Проверка на завершение таймера
			if (timeDifference < 0) {
				clearInterval(this.timerLeftTime);
			}
		}
	},

	mounted: function () {
		this.timerId = setInterval(() => this.checkStatus(), 3000);
		this.calculateLeftTime();
		this.timerLeftTime = setInterval(() => this.calculateLeftTime(), 1000);
	},

	unmounted: function () {
		if (this.timerId) {
			clearInterval(this.timerId);
		}

		if (this.timerLeftTime) {
			clearInterval(this.timerLeftTime);
		}
	}
}
</script>
