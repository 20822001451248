import postman from "@/functions/postman"

export default {
	state: {
		user: {
			auth_token: localStorage.getItem('_auth_token') || null,
			balance: {
				active: localStorage.getItem('_balance_active') || 0,
				freeze: localStorage.getItem('_balance_freeze') || 0,
				full: localStorage.getItem('_balance_full') || 0
			},
			user_name_header: localStorage.getItem('_user_name_header') || null,
			id: localStorage.getItem('_id') || null,
			email: localStorage.getItem('_email') || null,
			avatar: localStorage.getItem('_avatar') || null,

			user_name: localStorage.getItem('user_name') || null,
			user_surname: localStorage.getItem('user_surname') || null,
			user_country: localStorage.getItem('user_country') || null,
			KYC_verif: localStorage.getItem('KYC_verif') || null,
			KYC_verif_force: localStorage.getItem('KYC_verif_force') || 0,
			W_PASSWORD: localStorage.getItem('W_PASSWORD') || 0,
			FA2: localStorage.getItem('FA2') || 0,
			timeZone: localStorage.getItem('timeZone') || 'Europe/London',
		}
	},

	getters: {
		AUTH_TOKEN: state => {
			return state.user.auth_token
		},
		BALANCE: state => {
			return {
				active: state.user.balance.active,
				freeze: state.user.balance.freeze,
				full: state.user.balance.full
			};
		},
		USER_NAME_HEADER: state => {
			return state.user.user_name_header;
		},
		USER_ID: state => {
			return state.user.id;
		},
		USER_EMAIL: state => {
			return state.user.email;
		},
		USER_AVATAR: state => {
			return state.user.avatar;
		},

		USER_NAME: state => {
			return state.user.user_name;
		},
		USER_SURNAME: state => {
			return state.user.user_surname;
		},
		USER_COUNTRY: state => {
			return state.user.user_country;
		},

		KYC_VERIF: state => {
			return state.user.KYC_verif;
		},
		KYC_VERIF_FORCE: state => {
			return state.user.KYC_verif_force;
		},
		W_PASSWORD: state => {
			return state.user.W_PASSWORD;
		},
		FA2: state => {
			return state.user.FA2;
		},
		TIMEZONE: state => {
			return state.user.timeZone;
		}
	},

	mutations: {
		timeZone(state, timeZone) {
			state.user.timeZone = timeZone
			localStorage.setItem('timeZone', timeZone);
		},
		auth_success(state, token, timeZone = null) {
			state.user.auth_token = token
			state.user.timeZone = timeZone || 'Europe/London'
			localStorage.setItem('_auth_token', token);
			localStorage.setItem('timeZone', timeZone);
		},
		user_info(state, data) {
			state.user.id = data._id;
			localStorage.setItem('_id', data._id);

			state.user.user_name_header = data._user_name_header;
			localStorage.setItem('_user_name_header', data._user_name_header);

			state.user.user_name_header = data._email;
			localStorage.setItem('_email', data._email);

			if (data._avatar == 'none') {
				data._avatar = `${process.env.VUE_APP_API_DOMAIN}/_files/_avatar/0.jpg`;
			} else {
				data._avatar = `${process.env.VUE_APP_API_DOMAIN}/_files/_avatar/${data._avatar}`;
			}

			state.user.avatar = data._avatar;
			localStorage.setItem('_avatar', data._avatar);

			state.user.user_name = data.user_name;
			state.user.user_surname = data.user_surname;
			state.user.user_country = data.user_country;

			if (data.user_name) {
				localStorage.setItem('user_name', data.user_name);
			} else {
				localStorage.removeItem("user_name");
			}

			if (data.user_surname) {
				localStorage.setItem('user_surname', data.user_surname);
			} else {
				localStorage.removeItem("user_surname");
			}

			if (data.user_country) {
				localStorage.setItem('user_country', data.user_country);
			} else {
				localStorage.removeItem("user_country");
			}
		},
		balance(state, data) {
			state.user.balance = data;

			localStorage.setItem('_balance_active', data.active);
			localStorage.setItem('_balance_freeze', data.freeze);
			localStorage.setItem('_balance_full', data.full);

			if ('KYC_verif' in data) {
				if (data.KYC_verif) {
					localStorage.setItem('KYC_verif', data.KYC_verif);
				} else {
					localStorage.removeItem("KYC_verif");
				}

				state.user.KYC_verif = data.KYC_verif;
			}
		},

		set_kyc_verif(state, value) {
			if (value) {
				localStorage.setItem('KYC_verif', value);
			} else {
				localStorage.removeItem("KYC_verif");
			}
			state.user.KYC_verif = value;
		},

		set_kyc_verif_force(state, value) {
			if (value) {
				localStorage.setItem('KYC_verif_force', value);
			} else {
				localStorage.removeItem("KYC_verif_force");
			}
			state.user.KYC_verif_force = value;
		},

		set_w_password(state, value) {
			if (value) {
				localStorage.setItem('W_PASSWORD', value);
			} else {
				localStorage.removeItem("W_PASSWORD");
			}
			state.user.W_PASSWORD = value;
		},

		set_fa2(state, value) {
			if (value) {
				localStorage.setItem('FA2', value);
			} else {
				localStorage.removeItem("FA2");
			}
			state.user.FA2 = value;
		},

		set_user_additionals(state, data) {
			state.user.user_name = data.user_name;
			state.user.user_surname = data.user_surname;
			state.user.user_country = data.user_country;

			if ('user_name' in data) {
				localStorage.setItem('user_name', data.user_name);
			} else {
				localStorage.removeItem("user_name");
			}

			if ('user_surname' in data) {
				localStorage.setItem('user_surname', data.user_surname);
			} else {
				localStorage.removeItem("user_surname");
			}

			if ('user_country' in data) {
				localStorage.setItem('user_country', data.user_country);
			} else {
				localStorage.removeItem("user_country");
			}

			if ('KYC_verif' in data) {
				if (data.KYC_verif) {
					localStorage.setItem('KYC_verif', data.KYC_verif);
				} else {
					localStorage.removeItem("KYC_verif");
				}

				state.user.KYC_verif = data.KYC_verif;
			}
		},

		set_avatar(state, _avatar) {
			if (_avatar == 'none') {
				_avatar = `${process.env.VUE_APP_API_DOMAIN}/_files/_avatar/0.jpg`;
			} else {
				_avatar = `${process.env.VUE_APP_API_DOMAIN}/_files/_avatar/${_avatar}`;
			}

			state.user.avatar = _avatar;
			localStorage.setItem('_avatar', _avatar);
		}
	},

	actions: {
		async getMyKYC({ commit }, { hash }) {
			let postData = {
				hash
			}

			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/get-my-kyc.php`, postData, this.getters.AUTH_TOKEN);
			if (post._success) {
				commit('set_user_additionals', post);
			}

			return post;
		},

		async signUp({ commit }, { email, password, phone, type, id_parent, tz }) {
			let postData = {
				email: email,
				password: password,
				phone: phone,
				type: type,
				id_parent: id_parent,
				tz: tz
			}

			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/sign-up.php`, postData);
			if (post._success) {
				commit('auth_success', post._token, post.timezone);
				commit('balance', post._balance);
				commit('user_info', post);
			}

			return post;
		},

		async saveAdDataUser({ commit }, { user_name, user_surname, user_country }) {
			let postData = {
				user_name: user_name,
				user_surname: user_surname,
				user_country: user_country
			}

			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/save-add-data.php`, postData, this.getters.AUTH_TOKEN);
			if (post._success) {
				commit('set_user_additionals', post);
			}

			return post;
		},

		async signIn({ commit }, { email, password }) {
			let postData = {
				email: email,
				password: password
			}

			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/sign-in.php`, postData);
			if (post._success) {
				commit('auth_success', post._token, post.timezone);
				commit('balance', post._balance);
				commit('user_info', post);
			}

			return post;
		},

		async signInViaGoogle({ commit }, code) {
			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/auth-google.php`, { code: code });
			if (post._success) {
				commit('auth_success', post._token, post.timezone);
				commit('balance', post._balance);
				commit('user_info', post);
			}

			return post;
		},

		async getBalance({ commit }) {
			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/balance.php`, {}, this.getters.AUTH_TOKEN);
			if (post._balance) {
				commit('timeZone', post.timezone);
				commit('balance', post._balance);

				if ('KYC_verif' in post) {
					commit('set_kyc_verif', post.KYC_verif);
				}

				if ('KYC_verif_force' in post) {
					commit('set_kyc_verif_force', post.KYC_verif_force);
				}

				if ('withdraw_password' in post) {
					commit('set_w_password', post.withdraw_password);
				}

				if ('FA2' in post) {
					commit('set_fa2', post.FA2);
				}
			}

			return post;
		}
	}
}
