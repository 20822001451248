<template>
	<div class="modal fade" id="newSupportTicket">
		<div class="modal-dialog modal-dialog_md">
			<div class="modal__content">

				<div class="tab-section">

					<div class="tab-content">
						<div class="tab-pane active">
							<div class="modal__header text_mediumbold text_upper">
								Support
								<button type="button" class="icon modal-close modal__close icon-close-circle"
									@click.prevent="modalFunction('close', null)"></button>
							</div>

							<div class="modal__body modal__body_padding">

								<div v-if="!formSended">
									<div v-if="version == 0">
										<div class="mb-xxs text_dark text_normal text_size-sm">Email:</div>
										<div class="form-group_sm form-wrap">
											<input type="email" v-model="form.email"
												class="form-control form-control_xs" placeholder="Enter your email *" required
												:disabled="form.wait_api === true">
										</div>
										<div class="invalid_input" v-if="invalid.email">
											Please enter your email address
										</div>
									</div>

									<div v-if="version == 0">
										<div class="mb-xxs text_dark text_normal text_size-sm">Name:</div>
										<div class="form-group_sm form-wrap">
											<input type="text" v-model="form.name" class="form-control form-control_xs"
												placeholder="Enter your name *" required :disabled="form.wait_api === true">
										</div>
										<div class="invalid_input" v-if="invalid.name">
											Please enter your name
										</div>
									</div>

									<div v-if="version == 1">
										<div class="mb-xxs text_dark text_normal text_size-sm">Category:</div>
										<div class="form-group_sm form-wrap" style="position: relative;">
											<select class="form-control form-control_xs" v-model="form.category"
												required :disabled="form.wait_api === true">
												<option value="0" disabled hidden>Select a ticket category</option>
												<option value="technical">Техническая проблема</option>
												<option value="account">Вопросы аккаунта</option>
												<option value="payment">Оплата и финансы</option>
												<option value="other">Другое</option>
											</select>

											<div class="resetSelectCategory" v-if="form.category.length > 3"
												@click="form.category = 0">
												<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
													<path fill="#9ea0a1"
														d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z">
													</path>
												</svg>
											</div>

											<div class="apperanseSelect">
												<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10">
													<path fill="#9ea0a1"
														d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z">
													</path>
												</svg>
											</div>
										</div>

										<div class="invalid_input" v-if="invalid.category">
											Select a ticket category
										</div>
									</div>

									<div>
										<div class="mb-xxs text_dark text_normal text_size-sm">Details:</div>
										<div class="form-group_sm form-wrap">
											<textarea v-model="form.body" placeholder="Provide your details *"
												class="form-control form-control_xs chat-block__form-control" required
												:disabled="form.wait_api === true"></textarea>
										</div>
										<div class="invalid_input" v-if="invalid.body">
											Provide details about your issue
										</div>
									</div>

									<div v-if="$store.getters.AUTH_TOKEN && version == 1">
										<div class="mb-xxs text_dark text_normal text_size-sm">File:</div>
										<div class="form-group_sm form-wrap">
											<input type="file" @change="checkFileType('file')" ref="file" name="file"
												accept="image/*" class="form-control form-control_xs"
												:disabled="form.wait_api === true">
										</div>
									</div>

									<div @click.prevent="newTicket()" class="form-group"
										style="margin-top: 20px; position: relative;">
										<button v-if="form.wait_api === false" class="btn btn_size-sm-large w-100"
											type="button" :disabled="form.wait_api === true">
											Send
										</button>
										<div v-else>
											<div style="text-align: center; padding: 20px; position: relative;">
												<loading :active="true" :is-full-page="false" color="#583afb"
													loader="dots">
												</loading>
											</div>
										</div>
									</div>
								</div>

								<div v-else>
									Thank you! Your ticket has been successfully submitted. You will receive a
									confirmation email shortly. Our support team is reviewing your request and will get
									back to you by email within 24 hours.

									<div style="margin-top: 16px; margin-bottom: 12px;">
										<button type="button" @click.prevent="modalFunction('close', null);" class="btn btn_size-sm-large w-100">
											Close the window
										</button>
									</div>
								</div>

							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</template>

<script>
import modalFunction from '@/functions/modal';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import postman from "@/functions/postman"
import { version } from 'vue';

export default {
	components: {
		Loading
	},

	props: {
		versionmodal: String,
		required: false
	},

	data: () => ({
		modalFunction,

		success: false,
		success_disable: false,

		form: {
			error: null,
			category: '0',
			body: '',
			file: '',
			name: '',
			wait_api: false,
			email: '',
			version: 0
		},

		version: 'none',

		invalid: {
			email: false,
			name: false,
			category: false,
			body: false
		},

		formSended: false
	}),

	methods: {

		async newTicket() {
			if (this.version == 1 && this.form.category == '0') {
				this.invalid.category = true;
				return
			} else {
				this.invalid.category = false;
			}

			if (this.version == 0 && this.form.email == '') {
				this.invalid.email = true;
				return
			} else {
				this.invalid.email = false;
			}

			if (this.version == 0 && this.form.name == '') {
				this.invalid.name = true;
				return
			} else {
				this.invalid.name = false;
			}

			if (this.form.body == '') {
				this.invalid.body = true;
				return
			} else {
				this.invalid.body = false;
			}

			if (this.version == 0) {
				this.form.category = 'new_traders_request';
			}

			this.form.wait_api = true;

			const formData = new FormData();
			if (this.$refs.file && this.$refs.file.files.length > 0) {
				const file = this.$refs.file.files[0];
				formData.append('file', file);
			}
			formData.append('category', this.form.category);
			formData.append('body', this.form.body);
			formData.append('email', this.form.email);
			formData.append('name', this.form.name);
			formData.append('version', this.form.version);

			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/support/new-zd.php`, formData, this.$store.getters.AUTH_TOKEN, true);
			if (post) {
				if (post.id == 0) {
					alert(post.error_input.info);
				} else {
					// alert('Сообщение отправлено.');
					this.formSended = true;

					this.form.name = '';
					this.form.email = '';
					this.form.category = '0';
					this.form.body = '';
					this.form.file = '';

					// this.modalFunction('close', null);
				}
			}
			this.form.wait_api = false;
		},

		isImage(file) {
			return file.type.startsWith('image/');
		},

		checkFileType(inputName) {
			// Получаем файл из input-а
			const fileInput = this.$refs[inputName];
			const file = fileInput.files[0];

			// Проверяем, выбран ли файл
			if (!file) {
				return;
			}

			// Проверяем, является ли файл изображением
			if (!this.isImage(file)) {
				alert('Image files only!');
				fileInput.value = null; // Обнуляем значение input-а
				return;
			}

			// Проверяем размер файла (максимум 5 МБ)
			const maxSize = 5 * 1024 * 1024;
			if (file.size > maxSize) {
				fileInput.value = null;
				alert('File size exceeds the limit (5 MB).');
				return;
			}

			// Сохраняем файл в соответствующем объекте
			this.form.file = file;
		},
	},

	mounted() {
		if (this.versionmodal == 'user' && this.$store.getters.AUTH_TOKEN) {
			this.version = 1;
		}

		if (this.versionmodal == 'user' && !this.$store.getters.AUTH_TOKEN) {
			this.version = 0;
		}

		if (this.versionmodal == 'index') {
			this.version = 0;
		}

		this.form.version = this.version;
	}
}
</script>

<style>
.apperanseSelect {
	position: absolute;
	top: 14px;
	right: 12px
}

.resetSelectCategory {
	position: absolute;
	top: 14px;
	right: 36px;
}

.resetSelectCategory:hover {
	cursor: pointer;
}

.invalid_input {
	margin-top: 1px !important;
	margin-bottom: 12px !important;
}
</style>
