<template>
	<div class="wrap section-before" id="wrap">
		<!-- header -->
		<header class="header d-flex align-items-center">
			<router-link :to="{ name: 'home' }" class="logo header__logo d-flex align-items-center">
				<div class="logo__image"><img src="@/assets/img/logo.svg" alt="" class="img-fluid"></div>
				<div class="logo__text"><img src="@/assets/img/logo-text.svg" alt="" class="img-fluid"></div>
			</router-link>

			<nav class="navbar pane d-xl-flex" :class="{ show: $store.getters.NAVBAR_MOBILE }">
				<ul class="nav-list d-xl-flex align-items-center">
					<li class="nav-list__item"><a href="#home" class="nav-list__link active" @click="closeMobileNavbar">Home</a></li>
					<li class="nav-list__item"><a href="#about" class="nav-list__link" @click="closeMobileNavbar">How it works</a></li>
					<li class="nav-list__item"><a href="#faq" class="nav-list__link" @click="closeMobileNavbar">About us</a></li>
					<li class="nav-list__item"><a href="/traders" class="nav-list__link" @click="closeMobileNavbar">Traders</a></li>
					<li class="nav-list__item"><a href="#privacy" class="nav-list__link" @click="closeMobileNavbar">Legal Information</a>
						<ul class="dropdowngpt">
                    <li><a href="#terms-of-use">Terms of Use</a></li>
                    <li><a href="#privacy-policy">Privacy Policy</a></li>
                </ul>
					</li>
					<li class="nav-list__item"><a href="#support" class="nav-list__link" @click="closeMobileNavbar">Support</a></li>
				</ul>
			</nav>

			<div class="login">
				<div class="d-none d-xl-flex d-flex align-items-center" v-if="!$store.getters.AUTH_TOKEN">
					<a href="javascript:;" class="d-flex login__link align-items-center modal-open"
						@click="modalFunction($event, 'open', 'login')">
						<div class="circle-image login__image">
							<div class="circle-image__icon icon icon-user-circle"></div>
						</div>
						Sign in
					</a>
					<span class="text_dark login__separator">or</span>
					<a href="javascript:;" class="btn modal-open"
						@click="modalFunction($event, 'open', 'registration')">Sign up</a>
				</div>
				<a href="#login" class="login__mobile-link modal-open icon d-xl-none icon-login"
					@click="modalFunction($event, 'open', 'login')"></a>
			</div>

			<div v-if="$store.getters.AUTH_TOKEN" class="user-info d-none d-xl-block">
				<a href="javascript:;" class="d-flex align-items-center user-info__dropdown-toggle modal-open"
					@click.prevent="modalFunction($event, 'open', 'modalProfile')">
					<div class="user-info__image"><img :src="$store.getters.USER_AVATAR" alt=""></div>
					<div class="user-info__text">
						<div class="text_normal user-info__name text_size-sm">{{ $store.getters.USER_NAME_HEADER }}</div>
						<div class="text_primary text_mediumbold">{{ $store.getters.BALANCE.active }} $</div>
					</div>
				</a>
			</div>
			<router-link v-if="$store.getters.AUTH_TOKEN" to="/dashboard"
				class="d-none d-xl-inline-flex btn btn_size-xs modal-open">
				Back to dashboard
			</router-link>

			<a href="#navbar" class="btn-menu pane-open d-xl-none" :class="{ active: $store.getters.NAVBAR_MOBILE }"
				@click.prevent="openMobileNavbar">
				<div class="btn-menu__item"></div>
				<div class="btn-menu__item"></div>
				<div class="btn-menu__item"></div>
			</a>
		</header>
		<!-- /header -->

		<slot></slot>

		<!-- footer -->
		<footer class="footer">
			<div class="container">
				<ul class="footer-nav d-lg-flex text_size-sm">
					<li class="footer-nav__item"><a href="/terms-of-use" class="footer-nav__link">Terms of Use</a></li>
					<li class="footer-nav__item"><a href="/deposit-withdrawal-policy" class="footer-nav__link">Deposit and Withdraw Policy</a></li>
					<li class="footer-nav__item"><a href="/privacy-policy" class="footer-nav__link">Privacy Policy</a></li>
					<li class="footer-nav__item"><a href="/cookie-policy" class="footer-nav__link">Cookie Policy</a></li>
				</ul>
				<div class="footer__content d-flex text_size-sm align-items-center">
					<div class="d-flex">
						<a href="/" class="logo footer__logo">
							<div class="logo__image"><img src="@/assets/img/logo.svg" alt="" class="img-fluid"></div>
						</a>
						<div class="copyright">
							<div class="text_normal">Copyright © {{ new Date().getFullYear() }} &nbsp;</div>
							<div class="text_dark">All rights reserved. </div>
						</div>
					</div>
					<div class="d-lg-flex footer__contacts d-none">
						<a href="tel:" class="d-flex align-items-center">
							<span class="icon icon-phone-primary me-1"></span>
							+370 403-67-26
						</a>
						<a href="mailto:support@mytraders.com" class="d-flex align-items-center text_primary">
							<span class="icon icon-email-primary me-1"></span>
							support@mytraders.com
						</a>
						<div class="d-flex align-items-center text_dark">
							<span class="icon icon-pin-primary me-1"></span>
							Lithuania, Vilnius, Ozo 15
						</div>
					</div>
					<a href="javascript:;" @click.prevent="toTopFunction()" class="up-link icon icon-arrow-top-primary"></a>
				</div>
			</div>
		</footer>
		<!-- /footer -->

		<ModalLogin v-if="!$store.getters.AUTH_TOKEN" />
		<ModalReg v-if="!$store.getters.AUTH_TOKEN" />
		<ModalRecovery v-if="!$store.getters.AUTH_TOKEN" />
		<ProfileModal v-if="$store.getters.AUTH_TOKEN" />
		<InvestModal v-if="$store.getters.AUTH_TOKEN" />
	</div>
</template>

<script>
import ModalLogin from '@/components/unauthorized/ModalLogin.vue';
import ModalReg from '@/components/unauthorized/ModalReg.vue';
import ModalRecovery from '@/components/unauthorized/ModalRecovery.vue';
import modalFunction from '@/functions/modal';
import toTopFunction from '@/functions/toTop';
import scrollTo from '@/functions/scrollTo';

import ProfileModal from '@/components/cabinet/modals/Profile.vue';
import InvestModal from '@/components/cabinet/modals/Invest.vue';

export default {
	name: 'unauthorized',

	components: {
		ModalLogin, ModalReg, ProfileModal, InvestModal, ModalRecovery
	},

	setup() {
		return {
			modalFunction,
			toTopFunction,
			scrollTo
		};
	},

	methods: {
		openMobileNavbar: function () {
			this.$store.commit('open_close_mobile_menu', false)

			if (this.$store.getters.NAVBAR_MOBILE) {
				this.$store.commit('open_close_mobile_navbar', false)
			} else {
				this.$store.commit('open_close_mobile_navbar', true)
			}
		},
		closeMobileNavbar: function() {
			this.$store.commit('open_close_mobile_navbar', false);
		}
	}
}
</script>
