import store from "@/store";

export default function blackListCountries() {
	const country = store.getters.USER_COUNTRY;

	if (!country) return false;

	const blackListCountries = [
		'AF',
		'AQ'
	];

	if (blackListCountries.includes(country)) {
		return true;
	} else {
		return false;
	}
}
