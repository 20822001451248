import store from "@/store";
import router from "@/router";
import toTop from "./toTop";

export default function modal(e, method, name = '') {
	if (method == 'close' && !store.getters.AUTH_TOKEN) {
		localStorage.removeItem("saved_open_invest");
	}

	try {
		e.preventDefault();
	} catch (error) { }

	if (method == 'open' && (name == 'login' || name == 'registration') && store.getters.AUTH_TOKEN ) {
		router.push({name: 'dashboard'});
		toTop();
		return true;
	}
	
	let all_modals = document.querySelectorAll('.modal');

	all_modals.forEach((element) => {
		element.classList.remove('show');
	});

	if (method == 'open') {
		document.getElementById(name).classList.add('show');
		document.querySelector('body').classList.remove('modal-open');
		document.querySelector('body').classList.add('modal-open');
	}else{
		document.querySelector('body').classList.remove('modal-open');
	}

}
