<template>
	<div class="wrap" id="wrap">

		<div class="renew-block" v-if="$store.getters.KYC_VERIF_FORCE == 1">
			Please complite the verification to withdraw the funds or contact support
		</div>

		<!-- header -->
		<header class="header d-flex d-xl-none align-items-center justify-content-between">
			<a href="javascript:;" class="btn-menu pane-open d-xl-none" :class="{ active: $store.getters.NAVBAR_MOBILE }"
				@click.prevent="openMobileNavbar" v-if="!$store.getters.AUTH_TOKEN">
				<div class="btn-menu__item"></div>
				<div class="btn-menu__item"></div>
				<div class="btn-menu__item"></div>
			</a>

			<router-link to="/dashboard" class="logo header__logo d-flex align-items-center">
				<div class="logo__image"><img src="@/assets/img/logo.svg" alt="" class="img-fluid"></div>
				<div class="logo__text"><img src="@/assets/img/logo-text.svg" alt="" class="img-fluid"></div>
			</router-link>

			<nav class="navbar pane d-xl-flex" :class="{ show: $store.getters.NAVBAR_MOBILE }" id="onlyMobMenu">
				<ul class="nav-list d-xl-flex align-items-center">
					<router-link v-for="(link, index) in navigations" :key="index" tag="li" :to="link.to"
						@click.prevent="hideMobMenu();" :exact="link.exact" active-class="active" class="nav-list__item"
						v-slot="{ isActive, isExactActive }">
						<a :href="link.to" class="nav-list__link" :class="{ 'active': isActive }">
							{{ link.title }}
						</a>
					</router-link>

					<li class="nav-list__item" v-if="!$store.getters.AUTH_TOKEN">
						<a href="javascript:;" class="nav-list__link"
							@click.prevent="modalFunction($event, 'open', 'login')">
							Log In
						</a>
					</li>

					<router-link to="/exit" class="nav-list__item" v-if="$store.getters.AUTH_TOKEN">
						<a href="/exit" class="nav-list__link">
							Log Out
						</a>
					</router-link>
				</ul>
			</nav>

			<div class="login" v-if="$store.getters.AUTH_TOKEN">
				<a href="javascript:;" class="login__mobile-link pane-open icon d-xl-none icon-login"
					:class="{ active: $store.getters.MOBILE_MENU }" @click.prevent="openMobileMenu"></a>
			</div>
			<div class="login" v-else>
				<a href="javascript:;" class="login__mobile-link pane-open icon d-xl-none icon-login"
					:class="{ active: $store.getters.MOBILE_MENU }"
					@click.prevent="modalFunction($event, 'open', 'login')"></a>
			</div>
		</header>
		<!-- /header -->

		<div class="d-xl-flex content">
			<!-- aside -->
			<LeftBar />
			<!-- /aside -->

			<!-- main -->
			<main class="main">
				<!-- main__header -->
				<div class="main__header d-flex align-items-center">
					<div class="main__title text_mediumbold text_upper d-flex align-items-center">
						<span class="icon" :class="page_icon"></span>
						{{ page_name }}
					</div>

					<!-- <div class="fake-select main__language language dropdown d-none d-xl-block">
                        <a href="javascript:;" class="fake-select__link dropdown-toggle">
                            <span class="fake-select__value">
                                <div class="language__icon"><img src="@/assets/img/icons/ua.svg" alt="" class="img-fluid"></div>
                            </span>
                        </a>
                        <div class="fake-select__dropdown dropdown-menu">
                            <label class="fake-select__item active">
                                <input type="radio" class="collapse" name="language">
                                <span class="language__icon"><img src="@/assets/img/icons/ua.svg" alt="" class="img-fluid"></span>
                            </label>
                            <label class="fake-select__item">
                                <input type="radio" class="collapse" name="language">
                                <span class="language__icon"><img src="@/assets/img/icons/ru.svg" alt="" class="img-fluid"></span>
                            </label>
                            <label class="fake-select__item">
                                <input type="radio" class="collapse" name="language">
                                <span class="language__icon"><img src="@/assets/img/icons/gb.svg" alt="" class="img-fluid"></span>
                            </label>
                        </div>
                    </div> -->

					<div class="login" v-if="!$store.getters.AUTH_TOKEN">
						<div class="d-none d-xl-flex d-flex align-items-center">
							<a href="javascript:;" class="d-flex login__link align-items-center modal-open"
								@click="modalFunction($event, 'open', 'login')">
								<div class="circle-image login__image">
									<div class="circle-image__icon icon icon-user-circle"></div>
								</div>
								Sign in
							</a>
							<span class="text_dark login__separator">or</span>
							<a href="javascript:;" class="btn modal-open"
								@click="modalFunction($event, 'open', 'registration')">Sign up</a>
						</div>
						<!-- <a href="#login" class="login__mobile-link modal-open icon d-xl-none icon-login"
							@click="modalFunction($event, 'open', 'login')"></a> -->
					</div>

					<div v-if="$store.getters.AUTH_TOKEN" class="user-info d-none d-xl-block">
						<a href="javascript:;" class="d-flex align-items-center user-info__dropdown-toggle modal-open"
							@click.prevent="modalFunction($event, 'open', 'modalProfile')">
							<div class="user-info__image"><img :src="$store.getters.USER_AVATAR" alt=""></div>
							<div class="user-info__text">
								<div class="text_normal user-info__name text_size-sm">{{ $store.getters.USER_NAME_HEADER }}
								</div>
								<div class="text_primary text_mediumbold">{{ formatNumbers($store.getters.BALANCE.full) }} $</div>
							</div>
						</a>
					</div>
					<a v-if="$store.getters.AUTH_TOKEN" href="javascript:;"
						class="d-none d-xl-inline-flex btn btn_size-xs modal-open"
						@click.prevent="modalFunction($event, 'open', 'modalBalance')">Add Funds</a>
				</div>
				<!-- /main__header -->

				<slot></slot>
			</main>
			<!-- /main -->
		</div>

		<div class="copyright text_size-sm text_center copyright_bottom d-xl-none">
			<span class="text_normal">Copyright © {{ new Date().getFullYear() }}&nbsp;</span>
			<span class="text_dark">All rights reserved</span>
		</div>

		<ProfileModal v-if="$store.getters.AUTH_TOKEN" />
		<BalanceModal v-if="$store.getters.AUTH_TOKEN" />
		<InvestModal v-if="$store.getters.AUTH_TOKEN" />
		<BalanceOutModal v-if="$store.getters.AUTH_TOKEN" />

		<ModalLogin v-if="!$store.getters.AUTH_TOKEN" />
		<ModalReg v-if="!$store.getters.AUTH_TOKEN" />
		<ModalRecovery v-if="!$store.getters.AUTH_TOKEN" />
		<SupportModal versionmodal="user" />
	</div>
</template>

<script>
import LeftBar from '@/components/cabinet/LeftBar.vue';

import modalFunction from '@/functions/modal';
import ProfileModal from '@/components/cabinet/modals/Profile.vue';
import BalanceModal from '@/components/cabinet/modals/Balance.vue';
import InvestModal from '@/components/cabinet/modals/Invest.vue';
import BalanceOutModal from '@/components/cabinet/modals/BalanceOut.vue';

import ModalLogin from '@/components/unauthorized/ModalLogin.vue';
import ModalReg from '@/components/unauthorized/ModalReg.vue';
import ModalRecovery from '@/components/unauthorized/ModalRecovery.vue';

import SupportModal from '@/components/cabinet/modals/Support.vue';

import menuMixin from '@/mixins/menu.js';

import formatNumbers from '@/functions/formatNumbers';

export default {
	mixins: [menuMixin],
	name: 'cabinet',

	components: {
		LeftBar,
		ProfileModal,
		BalanceModal,
		InvestModal,
		BalanceOutModal,
		ModalLogin,
		ModalReg,
		ModalRecovery,
		SupportModal
	},

	watch: {
		'$route'(to, from) {
			this.page_name = to.meta.page_name;
			this.page_icon = to.meta.page_icon;
		}
	},

	mounted() {
		this.page_name = this.$route.meta.page_name;
		this.page_icon = this.$route.meta.page_icon;
	},

	data: () => ({
		page_name: null,
		page_icon: null,

		modalFunction,
		formatNumbers
	}),

	methods: {
		openMobileMenu: function () {
			this.$store.commit('open_close_mobile_navbar', false)

			if (this.$store.getters.MOBILE_MENU) {
				this.$store.commit('open_close_mobile_menu', false)
			} else {
				this.$store.commit('open_close_mobile_menu', true)
			}
		},
		openMobileNavbar: function () {
			this.$store.commit('open_close_mobile_menu', false)

			if (this.$store.getters.NAVBAR_MOBILE) {
				this.$store.commit('open_close_mobile_navbar', false)
			} else {
				this.$store.commit('open_close_mobile_navbar', true)
			}
		},

		hideMobMenu() {
			this.$store.commit('open_close_mobile_navbar', false)
		}
	}
}
</script>
