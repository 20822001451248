import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import generateTitle from '@/functions/generateTitle'
import modalFunction from '@/functions/modal';
import loginURL from '@/functions/loginURL';

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/unauthorized/IndexView.vue'),
		meta: {
			layout: "unauthorized",
			titlePage: 'Mytraders.io - your copy trading service'
		}
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('@/views/cabinet/CabinetIndexView.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'Dashboard',
			page_icon: 'icon-widget-double',
			auth: true,

			titlePage: 'Dashboard'
		}
	},
	{
		path: '/traders',
		name: 'traders',
		component: () => import('@/views/cabinet/TradersView.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'All traders',
			page_icon: 'icon-chart-double',
			auth: false,

			titlePage: 'Traders'
		}
	},
	{
		path: '/trader/:id',
		name: 'trader',
		component: () => import('@/views/cabinet/TraderView.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'Trader profile',
			page_icon: 'icon-chart-double',
			auth: false,

			titlePage: 'Trader {login}'
		}
	},
	{
		path: '/wallet',
		name: 'wallet',
		component: () => import('@/views/cabinet/WalletView.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'Wallet',
			page_icon: 'icon-wallet-double-second',
			auth: true,

			titlePage: 'Wallet'
		}
	},
	{
		path: '/security',
		name: 'security',
		component: () => import('@/views/cabinet/SecurityView.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'Security',
			page_icon: 'icon-shield-double',
			auth: true,

			titlePage: 'Security'
		}
	},
	{
		path: '/referral',
		name: 'referral',
		component: () => import('@/views/cabinet/ReferralView.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'Referral system',
			page_icon: 'icon-bag-double',
			auth: true,

			titlePage: 'Referral'
		}
	},
	{
		path: '/support',
		name: 'support',
		component: () => import('@/views/cabinet/SupportView.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'Support',
			page_icon: 'icon-chat-double',
			auth: true,

			titlePage: 'Support'
		}
	},
	{
		path: '/exit',
		name: 'exit',
		meta: {
			auth: true
		}
	},
	{
		path: '/ref/:id',
		name: 'referal_link'
	},
	{
		path: '/new-ticket',
		name: 'new-ticket',
		component: () => import('@/views/cabinet/SupportNewView.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'Support',
			page_icon: 'icon-chat-double',
			auth: true,

			titlePage: 'New Ticket'
		}
	},
	{
		path: '/ticket/:id',
		name: 'ticket',
		component: () => import('@/views/cabinet/SupportTicketView.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'Support',
			page_icon: 'icon-chat-double',
			auth: true,

			titlePage: 'Ticket {id}'
		}
	},
	{
		path: '/:url',
		name: 'page',
		component: () => import('@/views/unauthorized/Page.vue'),
		meta: {
			layout: "unauthorized",
			page_name: 'Page n1',
		}
	},
	{
		path: '/trades',
		name: 'trades',
		component: () => import('@/views/cabinet/MyTrades.vue'),
		meta: {
			layout: "cabinet",
			page_name: 'History',
			page_icon: 'icon-widget-double',
			auth: true,

			titlePage: 'History of your trades'
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	const required_auth = to.matched.some(record => record.meta.auth);
	const route_name = to.matched.some(record => record.name);

	let title = generateTitle(to);
	document.title = title;

	if (store.getters.BURSES.length == 0) {
		store.dispatch('get_burses');
	}

	if (to.name == 'referal_link') {
		if (!store.getters.AUTH_TOKEN) {
			store.commit('id_parent', to.params.id);
			next('/');
			return false;
		} else {
			next('/dashboard');
			return false;
		}
	}

	if (to.name === 'exit') {
		store.commit('auth_success', null);
		next('/');
		return false;
	}

	const saved_open_invest = localStorage.getItem("saved_open_invest");
	if (to.name == 'dashboard' && saved_open_invest) {
		next(`/trader/${loginURL(saved_open_invest)}`);
		return;
	}

	if (!store.getters.AUTH_TOKEN && required_auth) {
		// next('/');

		try {
			modalFunction(null, 'open', 'login');
		}catch(e) {
			next('/');
			setTimeout(() => {
				modalFunction(null, 'open', 'login');
			}, 500);
		}

		return
	} else {
		if (store.getters.BURSES.length === 0) {
			store.dispatch('get_burses');
		}

		store.dispatch('getBalance');

		next();
	}
});

export default router
