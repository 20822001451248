<template>
	<!-- withdraw -->
	<div class="modal fade" id="withdraw">
		<div class="modal-dialog modal-dialog_md">
			<div class="modal__content">
				<form action="javascript:;">
					<div class="tab-section">

						<div class="tab-content" v-if="$store.getters.KYC_VERIF_FORCE == 1">
							<div class="tab-pane active">
								<div class="modal__header text_mediumbold text_upper">
									WITHDRAW
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click.prevent="close();"></button>
								</div>

								<div class="modal__body modal__body_padding-sm">
									<p>
										Please complete the verification to withdraw funds!
									</p>
								</div>
							</div>
						</div>

						<div class="tab-content" v-if="$store.getters.KYC_VERIF_FORCE == 0">

							<div class="tab-pane active" v-if="$store.getters.BALANCE.active < minimum && step != 998">
								<div class="modal__header text_mediumbold text_upper">
									WITHDRAW
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click.prevent="close();"></button>
								</div>

								<div class="modal__body modal__body_padding-sm">
									<p>Minimum withdrawal amount: {{ minimum }}$. Check if your balance is not locked in copy trading</p>

									<div class="form-group text_center">
										<a href="javascript:;" class="btn btn_size-sm w-100 tab-link"
											@click.prevent="close();">Understood</a>
									</div>
								</div>
							</div>

							<div class="tab-pane" :class="{ 'active': step == 1 }"
								v-if="$store.getters.BALANCE.active >= minimum">
								<div class="modal__header text_mediumbold text_upper">
									WITHDRAW (STEP 1)
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click.prevent="close();"></button>
								</div>
								<div class="modal__body modal__body_padding-sm">
									<div class="mb-xxs text_normal text_size-sm d-flex justify-content-between">
										<span class="text_dark">Select Amount</span>
										<span @click.prevent="setAvailable()" class="setAvailable">
											<span class="text_dark me-1">Available</span>
											{{ formatNumbers($store.getters.BALANCE.active) }} $
										</span>
									</div>

									<input type="number" :min="minimum" step="10" :max="$store.getters.BALANCE.active"
										class="range-input form-control text_center" v-model="Slider_start"
										@change="chSliderStart">

									<div class="range-block d-flex align-items-center">
										<div class="text_dark text_size-sm text_normal">{{ minimum }}$</div>
										<div ref="slider_input" @click="upd_slider"></div>
										<div class="text_dark text_size-sm text_normal">Max</div>
									</div>
									<p class="text_dark text_normal text_size-sm">Select Payment type</p>
									<div class="radio-list radio-list_clear">
										<label class="radio-list__item" :class="{ 'active': systemType == 'bank' }">
											<input type="radio" class="collapse" v-model="systemType" name="systemType"
												value="bank">
											<div class="radio-list__icon icon icon-pay-bank"></div>
											<div class="radio-list__text">Bank transfer</div>
										</label>
										<label class="radio-list__item" :class="{ 'active': systemType == 'crypta' }">
											<input type="radio" class="collapse" v-model="systemType" name="systemType"
												value="crypta">
											<div class="radio-list__icon icon icon-pay-bitcoin"></div>
											<div class="radio-list__text">Crypto transfer</div>
										</label>
									</div>
									<div class="form-group text_center">
										<button @click="toStep(2)" class="btn btn_size-sm w-100 tab-link"
											:disabled="Slider_start < minimum || Slider_start > $store.getters.BALANCE.active">NEXT</button>
									</div>
								</div>
							</div>

							<div class="tab-pane" :class="{ 'active': step == 2 }"
								v-if="$store.getters.BALANCE.active >= minimum && step == 2">
								<div class="modal__header text_mediumbold text_upper">
									withdraw (STEP 2)
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click.prevent="close();"></button>
								</div>
								<div class="modal__body modal__body_padding-sm">
									<div class="text_dark text_normal text_size-sm mb-xxs">Select Currency</div>

									<div class="form-group_sm">
										<FakeSelectCurrencies :systemType="systemType" type="out"
											@item-selected="handleItemSelected" />
									</div>

									<div v-for="(c, i) in selectItemCurr.rows">
										<div class="mb-xxs text_dark text_normal text_size-sm">{{ c.name }}</div>
										<div class="form-group_sm form-wrap">
											<input class="form-control form-control_xs" v-model="c.value"
												:placeholder="c._placeholder">
										</div>
									</div>
									<div>
										<div class="mb-xxs text_dark text_normal text_size-sm">
											Amount ({{ selectItemCurr.code }})
										</div>
										<div class="form-group_sm form-wrap">
											<input class="form-control form-control_xs" v-model="amountInExcange" readonly>
										</div>
									</div>

									<div class="form-group text_center d-flex">
										<a href="javascript:;" @click="toStep(1)"
											class="btn btn_light btn_size-sm w-100 tab-link">Back</a>
										<button class="btn btn_size-sm w-100 tab-link"
											@click.prevent="withdraw();">Withdraw</button>
									</div>
								</div>
							</div>

							<div class="tab-pane" :class="{ 'active': step == 333 }" v-if="step == 333">
								<div class="modal__header text_mediumbold text_upper">
									2fa Authentication
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click.prevent="close();"></button>
								</div>

								<div class="modal__body modal__body_padding-sm">
									<div class="form-group">
										<label class="form-label">
											<span class="icon icon-lock-primary form-icon"></span>
											<input v-model="ipaid.fa2code" type="text" class="form-control"
												placeholder="2fa code"
												@click.prevent="fa2_error = null">
										</label>
										<div v-if="fa2_error" class="invalid_input">Invalid code!</div>
									</div>

									<div class="form-group text_center d-flex" v-if="wait_check_fa2 === false">
										<a href="javascript:;" @click="toStep(2)"
											class="btn btn_light btn_size-sm w-100 tab-link">Back</a>
										<button class="btn btn_size-sm w-100 tab-link"
											:disabled="(!ipaid.fa2code || ipaid.fa2code.length < 1)"
											@click.prevent="check_fa2();">Next</button>
									</div>
									<div v-else>
										<div style="text-align: center; padding: 20px; position: relative;">
											<loading :active="true" :is-full-page="false" color="#583afb" loader="dots">
											</loading>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane" :class="{ 'active': step == 222 }" v-if="step == 222">
								<div class="modal__header text_mediumbold text_upper">
									Withdraw password
									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click.prevent="close();"></button>
								</div>

								<div class="modal__body modal__body_padding-sm">
									<div class="form-group">
										<label class="form-label">
											<span class="icon icon-lock-primary form-icon"></span>
											<input v-model="ipaid.withdraw_password" type="password" class="form-control"
												placeholder="Withdraw password"
												@click.prevent="withdraw_password_error = null">
										</label>
										<div v-if="withdraw_password_error" class="invalid_input">Incorrect password
											withdraw!</div>
									</div>

									<div class="form-group text_center d-flex" v-if="wait_check_wpass === false">
										<a href="javascript:;" @click="toStep(2)"
											class="btn btn_light btn_size-sm w-100 tab-link">Back</a>
										<button class="btn btn_size-sm w-100 tab-link"
											:disabled="(!ipaid.withdraw_password || ipaid.withdraw_password.length < 5)"
											@click.prevent="check_withdraw_pass();">Next</button>
									</div>
									<div v-else>
										<div style="text-align: center; padding: 20px; position: relative;">
											<loading :active="true" :is-full-page="false" color="#583afb" loader="dots">
											</loading>
										</div>
									</div>
								</div>
							</div>

							<!-- finish transfer -->
							<div class="tab-pane" :class="{ 'active': step === 998 }">
								<div class="modal__header text_mediumbold text_upper">

									<span v-if="ipaid.wait_api === 1">Processing...</span>
									<span v-if="ipaid.wait_api === 0">
										<span v-if="ipaid.not_success === 0">Pending</span>
										<span v-if="ipaid.not_success === 1">Error</span>
									</span>

									<button type="button" class="icon modal-close modal__close icon-close-circle"
										@click.prevent="close();"></button>
								</div>

								<div class="modal__body modal__body_padding-sm" v-if="ipaid.wait_api === 0">
									<div class="d-flex text_size-sm mb-sm" v-if="ipaid.not_success === 0">
										<span class="icon icon-business me-1"></span>
										<div class="text_transparent">
											The withdrawal will be processed manually within 1-3 days
										</div>
									</div>

									<div class="d-flex text_size-sm mb-sm" v-if="ipaid.not_success === 1">
										{{ ipaid.error }}
									</div>

									<div class="form-group text_center">
										<a href="javascript:;" class="btn btn_size-sm modal-close"
											@click.prevent="close();">OK</a>
									</div>
								</div>
								<div class="modal__body modal__body_padding-sm" v-else>
									<div style="text-align: center; padding: 20px; position: relative;">
										<loading :active="true" :is-full-page="false" color="#583afb" loader="dots">
										</loading>
									</div>
								</div>
							</div>
							<!-- /finish transfer -->

						</div>

						<div class="modal__footer text_dark text_size-sm"
							v-if="step == 1 && $store.getters.BALANCE.active >= minimum && $store.getters.KYC_VERIF_FORCE == 0">
							<p>Minimum withdrawal amount: {{ minimum }}$.</p>
						</div>

						<div v-if="step == 2 && systemType == 'bank'" class="modal__footer text_dark text_size-sm">
							<p>Вывод (банковский платеж)</p>
						</div>

						<div v-if="step == 2 && systemType == 'crypta'" class="modal__footer text_dark text_size-sm">
							<p>Вывод крипта</p>
						</div>

					</div>
				</form>
			</div>
		</div>
	</div>
	<!-- /withdraw -->
</template>


<script>
import postman from '@/functions/postman';
import modalFunction from '@/functions/modal';
import noUiSlider from '@/assets/js/nouislider.min';
import '@/assets/css/nouislider.min.css';
import '@/assets/css/swiper-bundle.min.css';
import FakeSelectCurrencies from '@/components/FakeSelectCurrencies.vue';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import formatNumbers from '@/functions/formatNumbers';

export default {
	components: {
		FakeSelectCurrencies,
		Loading
	},

	data: () => ({
		modalFunction,
		formatNumbers,

		Slider: null,
		Slider_start: 10,
		minimum: 10,

		step: 1,
		selectItemCurr: {},

		ipaid: {
			currenc: null,
			amount: 0,
			request_type: 'out',
			wait_api: 0,
			not_success: 0,
			error: '',
			withdraw_password: null,
			fa2code: null
		},

		systemType: 'bank',

		wait_check_wpass: false,
		withdraw_password_error: null,
		withdraw_password_ok: false,

		wait_check_fa2: false,
		fa2_error: null,
		fa2_ok: false,
	}),

	methods: {
		async check_fa2() {
			this.wait_check_fa2 = true;

			let post = await postman(
				`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/set2fa.php`,
				{ approved_code: this.ipaid.fa2code, case: 'check' },
				this.$store.getters.AUTH_TOKEN
			);

			if (post) {
				if (post.result === true) {
					this.fa2_ok = true;
					await this.withdraw();
				} else {
					this.fa2_error = true;
				}
			}

			this.wait_check_fa2 = false;
		},

		async check_withdraw_pass() {
			this.wait_check_wpass = true;

			let post = await postman(
				`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/check_wpass.php`,
				{ password: this.ipaid.withdraw_password },
				this.$store.getters.AUTH_TOKEN
			);

			if (post) {
				if (post.result === true) {
					this.withdraw_password_ok = true;
					await this.withdraw();
				} else {
					this.withdraw_password_error = true;
				}
			}

			this.wait_check_wpass = false;
		},

		setAvailable() {
			this.Slider_start = this.$store.getters.BALANCE.active;
			this.Slider.noUiSlider.set(this.Slider_start);
		},

		close() {
			modalFunction('close', null);
			this.step = 1;
		},

		chSliderStart: function () {
			this.Slider.noUiSlider.set(this.Slider_start);
		},

		toStep(n) {
			if (n == 2) {
				this.wait_check_fa2 =false;
				this.fa2_error = null;
				this.fa2_ok = false;
				this.ipaid.fa2code = null;

				if (this.Slider > this.$store.getters.BALANCE.active) {
					return
				}
				if (this.Slider < this.minimum) {
					return
				}
			}
			this.step = n;
		},

		handleItemSelected(item) {
			this.selectItemCurr = item;
		},

		async withdraw() {

			for (let index = 0; index < this.selectItemCurr.rows.length; index++) {
				const r = this.selectItemCurr.rows[index];
				if (!r.value || r.value === null || r.value.trim == '') {
					alert('Пожалуйста, заполните все поля!')
					return;
				}
			}

			if (this.$store.getters.FA2 == 1 && this.fa2_ok === false) {
				this.step = 333;
				return;
			}

			if (this.$store.getters.W_PASSWORD == 1 && this.withdraw_password_ok === false) {
				this.step = 222;
				return;
			}

			this.ipaid.not_success = 0;
			this.ipaid.error = '';
			this.ipaid.wait_api = 1;
			this.step = 998;

			this.ipaid.currenc = this.selectItemCurr;
			this.ipaid.amount = this.Slider_start;

			let post = await postman(
				`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/request_out.php`,
				this.ipaid,
				this.$store.getters.AUTH_TOKEN
			);

			if (post.error_input) {
				this.ipaid.not_success = 1;
				this.ipaid.error = post.error_input.info;
			} else {
				if (post.status) {
					this.ipaid.not_success = 0;
				}
			}

			this.ipaid.wait_api = 0;

			await this.$store.dispatch('getBalance');
			if (this.$store.getters.BALANCE.active > 100) {
				this.Slider_start = Math.floor(this.$store.getters.BALANCE.active / 2);
			} else {
				this.Slider_start = Math.floor(this.$store.getters.BALANCE.active);
			}
		}
	},

	mounted: function () {

		if (this.$store.getters.BALANCE.active > 100) {
			this.Slider_start = Math.floor(this.$store.getters.BALANCE.active / 2);
		} else {
			this.Slider_start = Math.floor(this.$store.getters.BALANCE.active);
		}

		let ref_slider = this.$refs.slider_input;
		if (ref_slider) {
			this.Slider = ref_slider;

			noUiSlider.create(this.Slider, {
				connect: [true, false],
				range: {
					'min': this.minimum,
					'max': Math.floor(this.$store.getters.BALANCE.active)
				},
				start: [this.Slider_start],
				step: 10
			});

			this.Slider.noUiSlider.on('update', (values, handle) => {
				let newValue = Math.round(values[0]);
				this.Slider_start = newValue;
			});
		}
	},

	computed: {
		amountInExcange: {
			get() {
				return (this.Slider_start * this.selectItemCurr._exchange).toFixed(2);
			}
		},
	}
}
</script>
