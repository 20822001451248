<template>
	<!-- balance -->
	<div class="modal fade" id="modalBalance">
		<div class="modal-dialog modal-dialog_md">
			<div class="modal__content">

				<div class="tab-section">

					<div class="tab-content"
						v-if="!$store.getters.USER_NAME || !$store.getters.USER_SURNAME || !$store.getters.USER_COUNTRY || blackListCountries()">
						<div class="tab-pane active">
							<div class="modal__header text_mediumbold text_upper">
								Additional data
								<button type="button" class="icon modal-close modal__close icon-close-circle"
									@click.prevent="closeBalancePopUp();"></button>
							</div>

							<div class="modal__body modal__body_padding">
								<form action="javascript:;" style="position: relative;">
									<AdditionalData />
								</form>
							</div>
						</div>
					</div>

					<div class="tab-content" v-else>

						<div class="tab-pane" :class="{ 'active': step === 1 }">
							<div class="modal__header text_mediumbold text_upper">
								Top up the wallet
								<button type="button" class="icon modal-close modal__close icon-close-circle"
									@click.prevent="closeBalancePopUp();"></button>
							</div>
							<div class="modal__body modal__body_padding">
								<form action="javascript:;" style="position: relative;">
									<loading :active="waitApiPayment" :is-full-page="false" color="#583afb"
										loader="spinner">
									</loading>
									<p class="text_dark text_normal text_size-sm">Choose a top-up method</p>
									<div class="radio-list">
										<label class="radio-list__item"
											:class="{ 'active': selectedPaymentMethod === 'bank' }">
											<input v-model="selectedPaymentMethod" type="radio" class="collapse"
												name="payment" value="bank" checked>
											<div class="radio-list__icon icon icon-pay-bank"></div>
											<div class="radio-list__text">Bank payment</div>
										</label>
										<label class="radio-list__item"
											:class="{ 'active': selectedPaymentMethod === 'btc' }">
											<input v-model="selectedPaymentMethod" type="radio" class="collapse"
												value="btc" name="payment">
											<div class="radio-list__icon icon icon-pay-bitcoin"></div>
											<div class="radio-list__text">Bitcoin</div>
										</label>
										<label class="radio-list__item"
											:class="{ 'active': selectedPaymentMethod === 'eth' }">
											<input v-model="selectedPaymentMethod" type="radio" class="collapse"
												value="eth" name="payment">
											<div class="radio-list__icon icon icon-pay-ethereum"></div>
											<div class="radio-list__text">Ethereum</div>
										</label>
										<label class="radio-list__item"
											:class="{ 'active': selectedPaymentMethod === 'tusdt' }">
											<input v-model="selectedPaymentMethod" type="radio" class="collapse"
												value="tusdt" name="payment">
											<div class="radio-list__icon icon icon-pay-tether"></div>
											<div class="radio-list__text">Tether USDT</div>
										</label>
									</div>
									<p class="text_dark text_normal text_size-sm">Top-up amount, $</p>
									<input class="range-input form-control text_center" type="number" step="10" min="10"
										v-model="Slider_start" @change="chSliderStart">
									<div class="range-block d-flex align-items-center">
										<div class="text_dark text_size-sm text_normal">10</div>
										<div ref="slider_input" @click="upd_slider"></div>
										<div class="text_dark text_size-sm text_normal">10k</div>
									</div>
									<div class="form-group text_center">
										<button class="btn btn_size-sm" @click.prevent="goToStepPay()"
											:disabled="waitApiPayment">
											NEXT
										</button>
									</div>
								</form>
							</div>
						</div>

						<div class="tab-pane" :class="{ 'active': step === 2 }">
							<div class="modal__header text_mediumbold text_upper">
								Top up the wallet
								<button type="button" class="icon modal-close modal__close icon-close-circle"
									@click.prevent="closeBalancePopUp();"></button>
							</div>
							<div class="modal__body modal__body_padding-sm">
								<form action="javascript:;">
									<div class="text_dark text_normal text_size-sm mb-xxs">Select Currency</div>
									<div class="form-group_sm">
										<FakeSelectCurrencies type="in" @item-selected="handleItemSelected" />
									</div>

									<div v-for="(c, i) in selectItemCurr.rows">
										<div class="mb-xxs text_dark text_normal text_size-sm">{{ c.name }}</div>
										<div class="form-group_sm form-wrap">
											<input class="form-control form-control_xs" :value="c.value"
												@click.prevent="copyToClipboard(c.value)" readonly>
											<a href="javascript:;" class="copy-link icon form-icon_right icon-doc"
												@click.prevent="copyToClipboard(c.value)"></a>
										</div>
									</div>
									<div>
										<div class="mb-xxs text_dark text_normal text_size-sm">Reference</div>
										<div class="form-group_sm form-wrap">
											<input class="form-control form-control_xs" :value="$store.getters.USER_ID"
												@click.prevent="copyToClipboard($store.getters.USER_ID)" readonly>
											<a href="javascript:;" class="copy-link icon form-icon_right icon-doc"
												@click.prevent="copyToClipboard($store.getters.USER_ID)"></a>
										</div>
									</div>
									<div>
										<div class="mb-xxs text_dark text_normal text_size-sm">Amount ({{
							selectItemCurr.code }})</div>
										<div class="form-group_sm form-wrap">
											<input class="form-control form-control_xs" v-model="amountInExcange"
												@click.prevent="copyToClipboard(amountInExcange)" readonly>
											<a href="javascript:;" class="copy-link icon form-icon_right icon-doc"
												@click.prevent="copyToClipboard(amountInExcange)"></a>
										</div>
									</div>

									<div class="form-group text_center d-flex btn-group">
										<a href="javascript:;" class="btn btn_light btn_size-sm w-100 tab-link"
											@click.prevent="step = 1">Back</a>
										<a href="javascript:;" class="btn btn_size-sm w-100 modal-open"
											@click.prevent="ipaidGo();">I PAID</a>
									</div>
								</form>
							</div>
						</div>

						<!-- finish transfer -->
						<div class="tab-pane" :class="{ 'active': step === 998 }">
							<div class="modal__header text_mediumbold text_upper">

								<span v-if="ipaid.wait_api === 1">Processing...</span>
								<span v-if="ipaid.wait_api === 0">
									<span v-if="ipaid.not_success === 0">Pending</span>
									<span v-if="ipaid.not_success === 1">Error</span>
								</span>


								<button type="button" class="icon modal-close modal__close icon-close-circle"
									@click.prevent="closeBalancePopUp();"></button>
							</div>

							<div class="modal__body modal__body_padding-sm" v-if="ipaid.wait_api === 0">
								<div class="d-flex text_size-sm mb-sm" v-if="ipaid.not_success === 0">
									<span class="icon icon-business me-1"></span>
									<div class="text_transparent">
										The transfer will be credited manually within 1-3 days.
									</div>
								</div>

								<div class="d-flex text_size-sm mb-sm" v-if="ipaid.not_success === 1">
									{{ ipaid.error }}
								</div>

								<div class="form-group text_center">
									<a href="javascript:;" class="btn btn_size-sm modal-close"
										@click.prevent="closeBalancePopUp();">OK</a>
								</div>
							</div>
							<div class="modal__body modal__body_padding-sm" v-else>
								<div style="text-align: center; padding: 20px; position: relative;">
									<loading :active="true" :is-full-page="false" color="#583afb" loader="dots">
									</loading>
								</div>
							</div>
						</div>
						<!-- /finish transfer -->

						<!-- paymentInfo -->
						<div class="tab-pane" :class="{ 'active': step === 888 }">
							<div class="modal__header text_mediumbold text_upper">
								Deposit Funds
								<button type="button" class="icon modal-close modal__close icon-close-circle"
									@click.prevent="closeBalancePopUp();"></button>
							</div>
							<div class="modal__body modal__body_padding-sm">
								<form action="javascript:;">
									<div style="margin-bottom: 14px;">
										<div class="mb-xxs text_normal text_size-sm"
											style="display: flex; align-items: center;">

											<span v-if="selectedPaymentMethod == 'btc'" class="crypto-name">
												<img class="minCryptaIcon" src="@/assets/img/btc-min.png"> Bitcoin
											</span>

											<span v-if="selectedPaymentMethod == 'eth'" class="crypto-name">
												<img class="minCryptaIcon" src="@/assets/img/eth-min.png"> Ethereum
											</span>

											<span v-if="selectedPaymentMethod == 'tusdt'" class="crypto-name">
												<img class="minCryptaIcon" src="@/assets/img/tether-min.png"> Tether
												USDT
											</span>

											&nbsp;({{ infoApiPayment.pay_currency }})

										</div>
									</div>

									<ViewPayStatus v-if="infoApiPayment.payment_id"
										:paymentId="infoApiPayment.payment_id" :status="infoApiPayment.payment_status"
										:pay_amount="infoApiPayment.pay_amount"
										:pay_address="infoApiPayment.pay_address"
										:timer20min="infoApiPayment.timer20min" />
								</form>
							</div>
						</div>
						<!-- /paymentInfo -->



					</div>

					<div class="modal__footer text_dark text_size-sm" v-if="step == 1 || step == 2">
						<div v-if="step == 1">

						</div>

						<div v-if="step == 2">
							<p class="infoblock">It's very important to send the payment with the correct
								<b>Reference</b>. If you enter it wrong, the payment won't be added automatically.</p>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
	<!-- /balance -->
</template>


<script>
import postman from '@/functions/postman';

import modalFunction from '@/functions/modal';

import noUiSlider from '@/assets/js/nouislider.min';
import '@/assets/css/nouislider.min.css';
import '@/assets/css/swiper-bundle.min.css';

import FakeSelectCurrencies from '@/components/FakeSelectCurrencies.vue';

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

import ViewPayStatus from '@/components/ViewPayStatus.vue';

import AdditionalData from '@/components/cabinet/AdditionalData.vue';
import blackListCountries from '@/functions/blackListCountries';

export default {
	components: {
		FakeSelectCurrencies,
		Loading,
		ViewPayStatus,
		AdditionalData
	},

	data: () => ({
		modalFunction,
		blackListCountries,

		Slider: null,
		Slider_start: 1000,

		step: 1,
		selectItemCurr: {},
		ipaid: {
			invest_trader: 0,
			currenc: null,
			amount: 0,
			request_type: 'in',
			wait_api: 0,
			not_success: 0,
			error: ''
		},

		selectedPaymentMethod: 'bank',
		waitApiPayment: false,
		infoApiPayment: {},
	}),

	mounted: function () {
		let sInterval = setInterval(() => {
			let ref_slider = this.$refs.slider_input;
			if (ref_slider) {
				clearInterval(sInterval);
				
				this.Slider = ref_slider;

				noUiSlider.create(this.Slider, {
					connect: [true, false],
					range: {
						'min': 10,
						'max': 10000
					},
					start: [this.Slider_start],
					step: 10
				});

				this.Slider.noUiSlider.on('update', (values, handle) => {
					let newValue = Math.round(values[0]);
					this.Slider_start = newValue;
				});
			}
		}, 50);
	},

	methods: {
		closePayModal() {
			this.step = 1;
			this.selectedPaymentMethod = 'bank';
			this.waitApiPayment = false;
			this.infoApiPayment = {};
			modalFunction('close', null);
		},

		async goToStepPay() {
			if (this.selectedPaymentMethod === 'bank') {
				this.step = 2;
				return true;
			}

			this.waitApiPayment = true;

			let post = await postman(
				`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/crypto-payments/create.php`,
				{
					invest_trader: 0,
					amount: this.Slider_start,
					selectedPaymentMethod: this.selectedPaymentMethod
				},
				this.$store.getters.AUTH_TOKEN
			);

			if (post?.status && post.status === true) {
				this.step = 888;
				this.infoApiPayment = post.payment;
				this.waitApiPayment = false;
			} else {
				this.waitApiPayment = false;
				if (post.error) alert(post.error);
			}
		},

		async ipaidGo() {
			this.ipaid.not_success = 0;
			this.ipaid.error = '';
			this.ipaid.wait_api = 1;
			this.step = 998;

			this.ipaid.currenc = this.selectItemCurr;
			this.ipaid.amount = this.Slider_start;
			this.ipaid.invest_trader = 0;

			let post = await postman(
				`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/request_in.php`,
				this.ipaid,
				this.$store.getters.AUTH_TOKEN
			);

			if (post.error_input) {
				this.ipaid.not_success = 1;
				this.ipaid.error = post.error_input.info;
			} else {
				if (post.status) {
					this.ipaid.not_success = 0;
				}
			}

			this.ipaid.wait_api = 0;
		},

		chSliderStart: function () {
			this.Slider.noUiSlider.set(this.Slider_start);
		},

		handleItemSelected(item) {
			this.selectItemCurr = item;
		},

		copyToClipboard(value) {
			navigator.clipboard.writeText(value)
				.then(() => {
					console.log('Text copied to clipboard')
				})
				.catch(err => {
					console.error('Error copying text: ', err)
				})
		},

		closeBalancePopUp() {
			this.step = 1;
			modalFunction('close', null);
		}
	},

	computed: {
		amountInExcange: {
			get() {
				return (this.Slider_start * this.selectItemCurr._exchange).toFixed(2);;
			}
		},
	}
}
</script>
