<template>
	<!-- profile -->
	<div class="modal fade" id="modalProfile">
		<div class="modal-dialog">
			<div class="modal__content">
				<div class="modal__header text_mediumbold text_upper">
					Profile
					<button type="button" class="icon modal-close modal__close icon-close-circle"
						@click.prevent="modalFunction($event, 'close')"></button>
				</div>
				<div class="modal__user-card user-card d-flex align-items-center">
					<div class="user-card__image circle-image circle-image_lg">

						<img :src="$store.getters.USER_AVATAR" @click.prevent="show = true" class="ishover img-fluid">

						<my-upload field="img" @crop-upload-success="cropUploadSuccess" lang-type="en" :no-square="true"
							v-model="show" :width="120" :height="120" :url="uploadUrl" :params="params"
							:headers="headers" img-format="png"></my-upload>

					</div>
					<div class="user-card__text">
						<p v-if="!$store.getters.USER_NAME || !$store.getters.USER_SURNAME || !$store.getters.USER_COUNTRY"
							class="text_normal text_size-lg">
							{{ $store.getters.USER_NAME_HEADER }}
						</p>

						<p v-else class="text_normal text_size-lg d-flex align-items-top" style="position: relative;">
						<div class="me-1">
							{{ $store.getters.USER_NAME }} {{ $store.getters.USER_SURNAME }}
						</div>
						<div style="position: absolute; right: -4px;top: -4px;">
							<country-flag :country='$store.getters.USER_COUNTRY.toLowerCase()' size='small'
								:shadow="true" />
						</div>
						</p>

						<p class="text_dark text_size-sm">Account ID : {{ $store.getters.USER_ID }}</p>
					</div>
				</div>
				<div class="modal__body">
					<Form @submit="changePassword">
						<div class="form-group">
							<label class="form-label">
								<span class="icon icon-email-primary form-icon"></span>
								<input type="email" class="form-control" placeholder="E-mail" v-model="email" readonly>
							</label>
						</div>
						<div class="text_upper text_mediumbold mb-xs">Timezone</div>

						<div class="dtimezone" style="position: relative;">
							<v-select v-model="myTimeZone" :options="listTimezone" placeholder="Enter your timezone">
							</v-select>
							<div v-if="savedTZ" style="position: absolute; bottom: -6px;" class="invalid_input valid_input">Succesfully changed</div>
						</div>

						<div class="text_upper text_mediumbold mb-xs">Change Password</div>

						<div class="form-group">
							<label class="form-label">
								<span class="icon icon-lock-primary form-icon"></span>
								<Field name="password_old" @click="form.error.password_old = null" type="password"
									class="form-control" placeholder="Enter current password" rules="required"
									:validateOnChange="true" />
							</label>
							<ErrorMessage class="invalid_input" name="password_old" />
							<div class="invalid_input" v-if="form.error.password_old">{{ form.error.password_old }}
							</div>
						</div>
						<div class="form-group">
							<label class="form-label">
								<span class="icon icon-lock-primary form-icon"></span>
								<Field name="password_new" @click="form.error.password_new = null" type="password"
									class="form-control" placeholder="New Password" rules="required|min:8"
									:validateOnChange="true" />
							</label>
							<ErrorMessage class="invalid_input" name="password_new" />
							<div class="invalid_input" v-if="form.error.password_new">{{ form.error.password_new }}
							</div>
						</div>
						<div class="form-group">
							<label class="form-label">
								<span class="icon icon-lock-primary form-icon"></span>
								<Field name="password_again" type="password" class="form-control"
									placeholder="Password again" rules="required|confirmed:password_new"
									:validateOnChange="true" />
							</label>
							<ErrorMessage class="invalid_input" name="password_again" />
						</div>

						<div class="form-group" style="margin-top: 14px;">
							<button type="submit" class="btn btn_size-sm w-100" :disabled="form.wait_api === 1">Change
								password</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
	<!-- /profile -->
</template>

<style>
.dtimezone .vs__dropdown-menu {
	top: -356px !important;
	bottom: 0 !important;
	z-index: 999999;
	min-height: 180px !important;
}
</style>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import CountryFlag from 'vue-country-flag-next'

import modalFunction from '@/functions/modal';

import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

defineRule('required', required);
defineRule('min', min);
defineRule('email', email);

configure({
	generateMessage: localize('ru', {
		messages: {
			required: 'Required field',
			min: 'Minimum 8 characters',
			email: 'Invalid email entered'
		},
	}),
});

defineRule('confirmed', (value, [target], ctx) => {
	if (value === ctx.form[target]) {
		return true;
	}
	return 'Passwords do not match';
});

import postman from '@/functions/postman';

import myUpload from 'vue-image-crop-upload';

export default {
	components: {
		Form,
		Field,
		ErrorMessage,
		'my-upload': myUpload,
		'country-flag': CountryFlag,
		'v-select': vSelect
	},

	data: () => ({
		modalFunction,

		email: null,

		form: {
			error: {
				password_old: null,
				password_new: null
			},
			wait_api: 0
		},

		show: false,
		params: {
			name: 'avatar'
		},
		headers: {
			Authorization: ''
		},
		uploadUrl: '',

		listTimezone: [
			"Africa/Abidjan",
			"Africa/Accra",
			"Africa/Addis_Ababa",
			"Africa/Algiers",
			"Africa/Asmara",
			"Africa/Bamako",
			"Africa/Bangui",
			"Africa/Banjul",
			"Africa/Bissau",
			"Africa/Blantyre",
			"Africa/Brazzaville",
			"Africa/Bujumbura",
			"Africa/Cairo",
			"Africa/Casablanca",
			"Africa/Ceuta",
			"Africa/Conakry",
			"Africa/Dakar",
			"Africa/Dar_es_Salaam",
			"Africa/Djibouti",
			"Africa/Douala",
			"Africa/El_Aaiun",
			"Africa/Freetown",
			"Africa/Gaborone",
			"Africa/Harare",
			"Africa/Johannesburg",
			"Africa/Juba",
			"Africa/Kampala",
			"Africa/Khartoum",
			"Africa/Kigali",
			"Africa/Kinshasa",
			"Africa/Lagos",
			"Africa/Libreville",
			"Africa/Lome",
			"Africa/Luanda",
			"Africa/Lubumbashi",
			"Africa/Lusaka",
			"Africa/Malabo",
			"Africa/Maputo",
			"Africa/Maseru",
			"Africa/Mbabane",
			"Africa/Mogadishu",
			"Africa/Monrovia",
			"Africa/Nairobi",
			"Africa/Ndjamena",
			"Africa/Niamey",
			"Africa/Nouakchott",
			"Africa/Ouagadougou",
			"Africa/Porto-Novo",
			"Africa/Sao_Tome",
			"Africa/Tripoli",
			"Africa/Tunis",
			"Africa/Windhoek",
			"America/Adak",
			"America/Anchorage",
			"America/Anguilla",
			"America/Antigua",
			"America/Araguaina",
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Catamarca",
			"America/Argentina/Cordoba",
			"America/Argentina/Jujuy",
			"America/Argentina/La_Rioja",
			"America/Argentina/Mendoza",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Salta",
			"America/Argentina/San_Juan",
			"America/Argentina/San_Luis",
			"America/Argentina/Tucuman",
			"America/Argentina/Ushuaia",
			"America/Aruba",
			"America/Asuncion",
			"America/Atikokan",
			"America/Bahia",
			"America/Bahia_Banderas",
			"America/Barbados",
			"America/Belem",
			"America/Belize",
			"America/Blanc-Sablon",
			"America/Boa_Vista",
			"America/Bogota",
			"America/Boise",
			"America/Cambridge_Bay",
			"America/Campo_Grande",
			"America/Cancun",
			"America/Caracas",
			"America/Cayenne",
			"America/Cayman",
			"America/Chicago",
			"America/Chihuahua",
			"America/Ciudad_Juarez",
			"America/Costa_Rica",
			"America/Creston",
			"America/Cuiaba",
			"America/Curacao",
			"America/Danmarkshavn",
			"America/Dawson",
			"America/Dawson_Creek",
			"America/Denver",
			"America/Detroit",
			"America/Dominica",
			"America/Edmonton",
			"America/Eirunepe",
			"America/El_Salvador",
			"America/Fort_Nelson",
			"America/Fortaleza",
			"America/Glace_Bay",
			"America/Goose_Bay",
			"America/Grand_Turk",
			"America/Grenada",
			"America/Guadeloupe",
			"America/Guatemala",
			"America/Guayaquil",
			"America/Guyana",
			"America/Halifax",
			"America/Havana",
			"America/Hermosillo",
			"America/Indiana/Indianapolis",
			"America/Indiana/Knox",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Tell_City",
			"America/Indiana/Vevay",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Inuvik",
			"America/Iqaluit",
			"America/Jamaica",
			"America/Juneau",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Kralendijk",
			"America/La_Paz",
			"America/Lima",
			"America/Los_Angeles",
			"America/Lower_Princes",
			"America/Maceio",
			"America/Managua",
			"America/Manaus",
			"America/Marigot",
			"America/Martinique",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Menominee",
			"America/Merida",
			"America/Metlakatla",
			"America/Mexico_City",
			"America/Miquelon",
			"America/Moncton",
			"America/Monterrey",
			"America/Montevideo",
			"America/Montserrat",
			"America/Nassau",
			"America/New_York",
			"America/Nome",
			"America/Noronha",
			"America/North_Dakota/Beulah",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/Nuuk",
			"America/Ojinaga",
			"America/Panama",
			"America/Paramaribo",
			"America/Phoenix",
			"America/Port-au-Prince",
			"America/Port_of_Spain",
			"America/Porto_Velho",
			"America/Puerto_Rico",
			"America/Punta_Arenas",
			"America/Rankin_Inlet",
			"America/Recife",
			"America/Regina",
			"America/Resolute",
			"America/Rio_Branco",
			"America/Santarem",
			"America/Santiago",
			"America/Santo_Domingo",
			"America/Sao_Paulo",
			"America/Scoresbysund",
			"America/Sitka",
			"America/St_Barthelemy",
			"America/St_Johns",
			"America/St_Kitts",
			"America/St_Lucia",
			"America/St_Thomas",
			"America/St_Vincent",
			"America/Swift_Current",
			"America/Tegucigalpa",
			"America/Thule",
			"America/Tijuana",
			"America/Toronto",
			"America/Tortola",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Winnipeg",
			"America/Yakutat",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Macquarie",
			"Antarctica/Mawson",
			"Antarctica/McMurdo",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
			"Arctic/Longyearbyen",
			"Asia/Aden",
			"Asia/Almaty",
			"Asia/Amman",
			"Asia/Anadyr",
			"Asia/Aqtau",
			"Asia/Aqtobe",
			"Asia/Ashgabat",
			"Asia/Atyrau",
			"Asia/Baghdad",
			"Asia/Bahrain",
			"Asia/Baku",
			"Asia/Bangkok",
			"Asia/Barnaul",
			"Asia/Beirut",
			"Asia/Bishkek",
			"Asia/Brunei",
			"Asia/Chita",
			"Asia/Choibalsan",
			"Asia/Colombo",
			"Asia/Damascus",
			"Asia/Dhaka",
			"Asia/Dili",
			"Asia/Dubai",
			"Asia/Dushanbe",
			"Asia/Famagusta",
			"Asia/Gaza",
			"Asia/Hebron",
			"Asia/Ho_Chi_Minh",
			"Asia/Hong_Kong",
			"Asia/Hovd",
			"Asia/Irkutsk",
			"Asia/Jakarta",
			"Asia/Jayapura",
			"Asia/Jerusalem",
			"Asia/Kabul",
			"Asia/Kamchatka",
			"Asia/Karachi",
			"Asia/Kathmandu",
			"Asia/Khandyga",
			"Asia/Kolkata",
			"Asia/Krasnoyarsk",
			"Asia/Kuala_Lumpur",
			"Asia/Kuching",
			"Asia/Kuwait",
			"Asia/Macau",
			"Asia/Magadan",
			"Asia/Makassar",
			"Asia/Manila",
			"Asia/Muscat",
			"Asia/Nicosia",
			"Asia/Novokuznetsk",
			"Asia/Novosibirsk",
			"Asia/Omsk",
			"Asia/Oral",
			"Asia/Phnom_Penh",
			"Asia/Pontianak",
			"Asia/Pyongyang",
			"Asia/Qatar",
			"Asia/Qostanay",
			"Asia/Qyzylorda",
			"Asia/Riyadh",
			"Asia/Sakhalin",
			"Asia/Samarkand",
			"Asia/Seoul",
			"Asia/Shanghai",
			"Asia/Singapore",
			"Asia/Srednekolymsk",
			"Asia/Taipei",
			"Asia/Tashkent",
			"Asia/Tbilisi",
			"Asia/Tehran",
			"Asia/Thimphu",
			"Asia/Tokyo",
			"Asia/Tomsk",
			"Asia/Ulaanbaatar",
			"Asia/Urumqi",
			"Asia/Ust-Nera",
			"Asia/Vientiane",
			"Asia/Vladivostok",
			"Asia/Yakutsk",
			"Asia/Yangon",
			"Asia/Yekaterinburg",
			"Asia/Yerevan",
			"Atlantic/Azores",
			"Atlantic/Bermuda",
			"Atlantic/Canary",
			"Atlantic/Cape_Verde",
			"Atlantic/Faroe",
			"Atlantic/Madeira",
			"Atlantic/Reykjavik",
			"Atlantic/South_Georgia",
			"Atlantic/St_Helena",
			"Atlantic/Stanley",
			"Australia/Adelaide",
			"Australia/Brisbane",
			"Australia/Broken_Hill",
			"Australia/Darwin",
			"Australia/Eucla",
			"Australia/Hobart",
			"Australia/Lindeman",
			"Australia/Lord_Howe",
			"Australia/Melbourne",
			"Australia/Perth",
			"Australia/Sydney",
			"Europe/Amsterdam",
			"Europe/Andorra",
			"Europe/Astrakhan",
			"Europe/Athens",
			"Europe/Belgrade",
			"Europe/Berlin",
			"Europe/Bratislava",
			"Europe/Brussels",
			"Europe/Bucharest",
			"Europe/Budapest",
			"Europe/Busingen",
			"Europe/Chisinau",
			"Europe/Copenhagen",
			"Europe/Dublin",
			"Europe/Gibraltar",
			"Europe/Guernsey",
			"Europe/Helsinki",
			"Europe/Isle_of_Man",
			"Europe/Istanbul",
			"Europe/Jersey",
			"Europe/Kaliningrad",
			"Europe/Kirov",
			"Europe/Kyiv",
			"Europe/Lisbon",
			"Europe/Ljubljana",
			"Europe/London",
			"Europe/Luxembourg",
			"Europe/Madrid",
			"Europe/Malta",
			"Europe/Mariehamn",
			"Europe/Minsk",
			"Europe/Monaco",
			"Europe/Moscow",
			"Europe/Oslo",
			"Europe/Paris",
			"Europe/Podgorica",
			"Europe/Prague",
			"Europe/Riga",
			"Europe/Rome",
			"Europe/Samara",
			"Europe/San_Marino",
			"Europe/Sarajevo",
			"Europe/Saratov",
			"Europe/Simferopol",
			"Europe/Skopje",
			"Europe/Sofia",
			"Europe/Stockholm",
			"Europe/Tallinn",
			"Europe/Tirane",
			"Europe/Ulyanovsk",
			"Europe/Vaduz",
			"Europe/Vatican",
			"Europe/Vienna",
			"Europe/Vilnius",
			"Europe/Volgograd",
			"Europe/Warsaw",
			"Europe/Zagreb",
			"Europe/Zurich",
			"Indian/Antananarivo",
			"Indian/Chagos",
			"Indian/Christmas",
			"Indian/Cocos",
			"Indian/Comoro",
			"Indian/Kerguelen",
			"Indian/Mahe",
			"Indian/Maldives",
			"Indian/Mauritius",
			"Indian/Mayotte",
			"Indian/Reunion",
			"Pacific/Apia",
			"Pacific/Auckland",
			"Pacific/Bougainville",
			"Pacific/Chatham",
			"Pacific/Chuuk",
			"Pacific/Easter",
			"Pacific/Efate",
			"Pacific/Fakaofo",
			"Pacific/Fiji",
			"Pacific/Funafuti",
			"Pacific/Galapagos",
			"Pacific/Gambier",
			"Pacific/Guadalcanal",
			"Pacific/Guam",
			"Pacific/Honolulu",
			"Pacific/Kanton",
			"Pacific/Kiritimati",
			"Pacific/Kosrae",
			"Pacific/Kwajalein",
			"Pacific/Majuro",
			"Pacific/Marquesas",
			"Pacific/Midway",
			"Pacific/Nauru",
			"Pacific/Niue",
			"Pacific/Norfolk",
			"Pacific/Noumea",
			"Pacific/Pago_Pago",
			"Pacific/Palau",
			"Pacific/Pitcairn",
			"Pacific/Pohnpei",
			"Pacific/Port_Moresby",
			"Pacific/Rarotonga",
			"Pacific/Saipan",
			"Pacific/Tahiti",
			"Pacific/Tarawa",
			"Pacific/Tongatapu",
			"Pacific/Wake",
			"Pacific/Wallis"
		],
		myTimeZone: '',

		savedTZ: false
	}),

	beforeMount: function () {
		this.email = this.$store.getters.USER_EMAIL;
		this.headers.Authorization = `Bearer ${this.$store.getters.AUTH_TOKEN}`;
		this.uploadUrl = `${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/upload-avatar.php`;
	},

	methods: {
		handleOptionTimeZone(option) {
			console.log(option)
		},

		changePassword: async function (values) {
			let { password_old, password_new } = values;

			let postData = {
				password_old: password_old,
				password_new: password_new
			}

			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/change-password.php`, postData, this.$store.getters.AUTH_TOKEN);
			if (post.error_input) {
				this.form.error[post.error_input.input] = post.error_input.info;
				this.form.wait_api = 0;
				return
			}

			this.form.wait_api = 0;

			if (post._success) {
				this.$store.commit('auth_success', post._token);
				this.modalFunction('', 'close');
			}

		},

		cropUploadSuccess(jsonData, field) {
			if (jsonData?.change && jsonData.change === true && jsonData?._avatar) {
				let avatar = jsonData._avatar;
				this.$store.commit('set_avatar', avatar);
			}
			this.show = false;
		}
	},

	watch: {
		async myTimeZone(newVal, oldVal) {
			if (oldVal != '') {
				let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/change-timezone.php`, { set: newVal }, this.$store.getters.AUTH_TOKEN);
				if (post._success) {
					this.$store.commit('timeZone', post.newVal);

					this.savedTZ = true;
					setTimeout(() => {
						this.savedTZ = false;
					}, 2400);
				}
			}
		},

		show(newVal) {
			if (newVal) {
				// document.body.style.overflow = 'hidden';
				document.querySelector('.vue-image-crop-upload').style.height = '120%';
			}
		}
	},

	mounted() {
		this.myTimeZone = this.$store.getters.TIMEZONE;
	}
}
</script>
