<template>
<!-- login -->
<div class="modal fade" id="recovery">
    <div class="modal-dialog">
        <div class="modal__content">
            <div class="modal__header text_mediumbold text_upper">
                Reset Password
                <button type="button" class="icon modal-close modal__close icon-close-circle"
                    @click="modalFunction($event, 'close')"></button>
            </div>
            <div class="tab-section">
                <div class="modal__body">
                    <div class="tab-content">
                        <Form @submit="recoveryFormHandler" v-if="step === 1">
                            <div class="form-group">
                                <label class="form-label">
                                    <span class="icon icon-email-primary form-icon"></span>
                                    <Field name="email" @click="form.error.email = null" type="email"
                                        class="form-control" placeholder="E-mail" rules="required|email"
                                        :validateOnChange="true" />
                                </label>
                                <ErrorMessage class="invalid_input" name="email" />
                                <div class="invalid_input" v-if="form.error.email">{{ form.error.email }}</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <p>
                                    <button type="submit" class="btn btn_size-sm-large"
                                        :disabled="form.wait_api === 1">Send Code</button>
                                </p>
                            </div>
                        </Form>

                        <Form @submit="recoveryFormHandlerCode" v-if="step === 2">
                            <div class="form-group">
                                <p>Enter the code sent to:<br>{{ input_email }}</p>
                                <label class="form-label">
                                    <span class="icon icon-lock-primary form-icon"></span>
                                    <Field name="code" @click="form.error.code = null" type="text" class="form-control"
                                        placeholder="Enter code" rules="required" :validateOnChange="true" />
                                </label>
                                <ErrorMessage class="invalid_input" name="code" />
                                <div class="invalid_input" v-if="form.error.code">{{ form.error.code }}</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <p>
                                    <button type="submit" class="btn btn_size-sm-large"
                                        :disabled="form.wait_api === 1">Continue</button>
                                </p>
                            </div>
                        </Form>

                        <Form @submit="recoveryFormHandlerNewPass" v-if="step === 3">
                            <p>Create a new password</p>
                            <div class="form-group">
                                <label class="form-label">
                                    <span class="icon icon-lock-primary form-icon"></span>
                                    <Field name="password" @click="form.error.password = null" type="password"
                                        class="form-control" placeholder="Password" rules="required|min:8"
                                        :validateOnChange="true" />
                                </label>
                                <ErrorMessage class="invalid_input" name="password" />
                                <div class="invalid_input" v-if="form.error.password">{{ form.error.password }}</div>
                            </div>

                            <div class="form-group">
                                <label class="form-label">
                                    <span class="icon icon-lock-primary form-icon"></span>
                                    <Field name="password_again" type="password" class="form-control"
                                        placeholder="Confirm password" rules="required|confirmed:password"
                                        :validateOnChange="true" />
                                </label>
                                <ErrorMessage class="invalid_input" name="password_again" />
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <p>
                                    <button type="submit" class="btn btn_size-sm-large"
                                        :disabled="form.wait_api === 1">Continue</button>
                                </p>
                            </div>
                        </Form>

                        <div v-if="step == 999">
                            <p>
                                Password successfully changed!<br><br>
                                <button type="button" class="btn btn_size-sm-large w-100"
                                    @click.prevent="modalFunction($event, 'open', 'login')">Log In</button>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal__footer d-flex justify-content-between text_size-sm text_normal text_center_sm"
                    v-if="step != 999">
                    <p class="text_dark">Remembered your password?</p>
                    <p><a href="javascript:;" class="modal-open" @click="modalFunction($event, 'open', 'login')">Sign
                            in</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /login -->
</template>


<script>
import modalFunction from '@/functions/modal';

import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

import postman from "@/functions/postman"

defineRule('required', required);
defineRule('min', min);
defineRule('email', email);

configure({
	generateMessage: localize('en', {
		messages: {
			equired: 'Required field',
			min: 'Minimum 8 characters',
			email: 'Invalid email entered'
		},
	}),
});

export default {
	components: {
		Form,
		Field,
		ErrorMessage
	},

	data: () => ({
		modalFunction,

		form: {
			error: {
				email: null,
				code: null,
				password: null
			},
			wait_api: 0
		},

		input_email: '',
		input_code: '',
		step: 1
	}),

	methods: {
		recoveryFormHandlerNewPass: async function (values) {
			let { password, password_again } = values;

			let postData = {
				password: password,
				code: this.input_code,
				email: this.input_email,
				step: 3
			}

			this.form.wait_api = 1;

			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/recovery-password.php`, postData);
			if (post.error_input) {
				this.form.error[post.error_input.input] = post.error_input.info;
				this.form.wait_api = 0;
				return
			}

			this.form.wait_api = 0;

			if (post.to_step) {
				this.step = post.to_step;
			}
		},

		recoveryFormHandlerCode: async function (values) {
			let { code } = values;

			let postData = {
				code: code,
				email: this.input_email,
				step: 2
			}

			this.input_code = code;

			this.form.wait_api = 1;

			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/recovery-password.php`, postData);
			if (post.error_input) {
				this.form.error[post.error_input.input] = post.error_input.info;
				this.form.wait_api = 0;
				return
			}

			this.form.wait_api = 0;

			if (post.to_step) {
				this.step = post.to_step;
			}
		},

		recoveryFormHandler: async function (values) {
			let { email } = values;

			let postData = {
				email: email,
				step: 1
			}

			this.input_email = email;

			this.form.wait_api = 1;
			// let response = await this.$store.dispatch('signIn', postData);
			// if ( response.error_input ) {
			// 	this.form.error[ response.error_input.input ] = response.error_input.info;
			// 	this.form.wait_api = 0;
			// 	return
			// }

			// this.form.wait_api = 0;

			// if ( response._success ) {
			// 	this.modalFunction('', 'close');
			// 	this.$router.push('/dashboard');
			// }

			let post = await postman(`${process.env.VUE_APP_API_DOMAIN}/${process.env.VUE_APP_API_VERSION}/user/recovery-password.php`, postData);
			if (post.error_input) {
				this.form.error[post.error_input.input] = post.error_input.info;
				this.form.wait_api = 0;
				return
			}

			this.form.wait_api = 0;

			if (post.to_step) {
				this.step = post.to_step;
			}

		}
	}
}
</script>
