<template>
	<div v-if="blackListCountries()">
		<p>Unfortunately, your country is currently not available for our services due to compliance reasons. List of
			forbidden
			countries!</p>
	</div>

	<div v-else>

		<div class="form-group">
			<label class="form-label form-label-custom">Country:</label>
			<v-select v-model="selected_country" :options="listCountries()" placeholder="Enter your country">
				<template #selected-option="{ label, code }">
					<div class="d-flex align-items-center">
						<country-flag :country='code.toLowerCase()' size='small' :shadow="true" />
						<div class="ms-2">{{ label }}</div>
					</div>
				</template>
				<template #option="{ label, code }">
					<div class="d-flex align-items-center">
						<country-flag :country='code.toLowerCase()' size='small' :shadow="true" />
						<div class="ms-2">{{ label }}</div>
					</div>
				</template>
			</v-select>

		</div>


		<div class="form-group">
			<label class="form-label form-label-custom">Name:</label>
			<input type="email" class="form-control" placeholder="Enter your name" v-model="user_name">
		</div>

		<div class="form-group">
			<label class="form-label form-label-custom">Last name:</label>
			<input type="email" class="form-control" placeholder="Enter your last name" v-model="user_surname">
		</div>

		<div class="form-group text_center">
			<button class="btn btn_size-sm" @click.prevent="saveAData()"
				:disabled="!selected_country || !user_name || !user_surname">
				Save
			</button>
		</div>
	</div>
</template>


<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import CountryFlag from 'vue-country-flag-next'

import listCountries from '@/functions/listCountries'
import blackListCountries from '@/functions/blackListCountries';

export default {
	components: {
		'v-select': vSelect,
		'country-flag': CountryFlag
	},

	data: () => ({
		blackListCountries,
		listCountries,

		user_name: null,
		user_surname: null,
		selected_country: null
	}),

	methods: {
		async saveAData() {
			if (this.user_name.length > 1 && this.user_surname.length > 1 && this.selected_country) {

				let ucountry = null;
				if (typeof this.selected_country === 'string') {
					const country = listCountries().find(country => country.label === this.selected_country);
					if (country) {
						ucountry = country.code;
					}
				} else {
					ucountry = this.selected_country.code;
				}

				let response = await this.$store.dispatch('saveAdDataUser', { user_name: this.user_name, user_surname: this.user_surname, user_country: ucountry });
				console.log(response)
			} else {
				return;
			}
		}
	},

	mounted() {
		this.user_name = this.$store.getters.USER_NAME;
		this.user_surname = this.$store.getters.USER_SURNAME;

		let country_code = this.$store.getters.USER_COUNTRY;
		if (country_code && country_code.length >= 2) {
			const country = listCountries().find(country => country.code === country_code);
			if (country) {
				this.selected_country = country;
			}
		}
	}
}
</script>
