<template>
	<div class="modal fade" id="login">
		<div class="modal-dialog">
			<div class="modal__content">
				<div class="modal__header text_mediumbold text_upper">
					Login
					<button type="button" class="icon modal-close modal__close icon-close-circle"
						@click="modalFunction($event, 'close')"></button>
				</div>
				<div class="tab-section">
					<div class="modal__body">
						<div class="tab-content">
							<Form @submit="authFormHandler">
								<div class="form-group">
									<label class="form-label">
										<span class="icon icon-email-primary form-icon"></span>
										<Field name="email" @click="form.error.email = null" type="email"
											class="form-control" placeholder="E-mail" rules="required|email"
											:validateOnChange="true" />
									</label>
									<ErrorMessage class="invalid_input" name="email" />
									<div class="invalid_input" v-if="form.error.email">{{ form.error.email }}</div>
								</div>
								<div class="form-group">
									<label class="form-label">
										<span class="icon icon-lock-primary form-icon"></span>
										<Field name="password" @click="form.error.password = null" type="password"
											class="form-control" placeholder="Password" rules="required"
											:validateOnChange="true" />
									</label>
									<ErrorMessage class="invalid_input" name="password" />
									<div class="invalid_input" v-if="form.error.password">{{ form.error.password }}
									</div>
								</div>
								<div class="d-flex justify-content-between align-items-center">
									<p>
										<button type="submit" class="btn btn_size-sm-large"
											:disabled="form.wait_api === 1">Log in</button>
									</p>
									<p class="text_center_sm">
										<a href="javascript:;"
											@click.prevent="modalFunction($event, 'open', 'recovery')"
											class="text_dark text_size-sm text_normal">Forgot password?</a>
									</p>
								</div>
								<div class="form-group text-center">
									<p class="login-text">Or continue with</p>
									<div class="login-buttons">
										<GoogleLogin :callback="callbackGoogleAuth">
											<button type="button" class="login-btn google-login">
												<img src="@/assets/img/icons/google.svg" alt="Google"
													class="login-icon">Google
											</button>
										</GoogleLogin>
										<button class="login-btn apple-login" @click="loginWithApple">
											<img src="@/assets/img/icons/apple.svg" alt="Apple" class="login-icon">Apple
										</button>
									</div>
								</div>
							</Form>
						</div>
					</div>
					<div class="modal__footer d-flex justify-content-between text_size-sm text_normal text_center_sm">
						<p class="text_dark">Don't have an account?</p>
						<p><a href="#registration" class="modal-open"
								@click.prevent="modalFunction($event, 'open', 'registration')">Sign up</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import modalFunction from '@/functions/modal';
import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

defineRule('required', required);
defineRule('min', min);
defineRule('email', email);

configure({
	generateMessage: localize('en', {
		messages: {
			required: 'Required field',
			min: 'Minimum 8 characters',
			email: 'Invalid email entered'
		},
	}),
});

export default {
	components: {
		Form,
		Field,
		ErrorMessage
	},

	data: () => ({
		modalFunction,
		form: {
			error: {
				email: null,
				password: null,
				phone: null
			},
			wait_api: 0
		},

		user: null
	}),

	methods: {
		authFormHandler: async function (values) {
			let { email, password } = values;
			let postData = {
				email: email,
				password: password
			};
			this.form.wait_api = 1;
			let response = await this.$store.dispatch('signIn', postData);
			if (response.error_input) {
				this.form.error[response.error_input.input] = response.error_input.info;
				this.form.wait_api = 0;
				return
			}
			this.form.wait_api = 0;
			if (response._success) {
				this.modalFunction('', 'close');
				this.$router.push('/dashboard');

				setTimeout(() => {
					location.reload();
				}, 500);
			}
		},

		async callbackGoogleAuth(gresponse) {
			if (gresponse?.code) {
				let code = gresponse.code;

				this.form.wait_api = 1;

				let response = await this.$store.dispatch('signInViaGoogle', code);

				if (response.error_input) {
					this.form.error[response.error_input.input] = response.error_input.info;
					this.form.wait_api = 0;
					return
				}
				this.form.wait_api = 0;

				this.form.wait_api = 0;
				if (response._success) {
					this.modalFunction('', 'close');
					this.$router.push('/dashboard');

					setTimeout(() => {
						location.reload();
					}, 500);
				}
			}
		}
	}
}
</script>
