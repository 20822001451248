import postman from "@/functions/postman"

export default {
	state: {
		sorting: {
			default: 0,
			level: 0
		}
  	},

  	getters: {
		TRADERS_SORTING_DEFAULT: state => {
			return state.sorting.default
		},
		TRADERS_SORTING_LEVEL: state => {
			return state.sorting.level
		},
  	},

  	mutations: {
		set_tarders_sorting_default(state, data) {
			state.sorting.default = data
		},
		set_tarders_sorting_level(state, data) {
			state.sorting.level = data
		},
  	},

	actions: {
		
	}
}
