import { createStore } from 'vuex'

import auth from './auth'
import burses from './burses'
import tradersView from './tradersView'
import user from './user'
import invest from './invest'

export default createStore({
  	state: {
		id_parent: localStorage.getItem('_id_parent') || 0,

		open_mobile_menu: false,
		open_mobile_navbar: false
  	},
  	getters: {
		ID_PARENT: state => {
			return state.id_parent;
		},
		MOBILE_MENU: state => {
			return state.open_mobile_menu;
		},
		NAVBAR_MOBILE: state => {
			return state.open_mobile_navbar;
		},
  	},
  	mutations: {
		id_parent(state, id) {
			state.id_parent = id
			localStorage.setItem('_id_parent', id);
		},
		open_close_mobile_menu(state, open = true) {
			state.open_mobile_menu = open;
			if (open == true) {
				document.body.classList.add('overflow-none');
			}else{
				document.body.classList.remove('overflow-none');
			}
		},
		open_close_mobile_navbar(state, open = true) {
			state.open_mobile_navbar = open;
			if (open == true) {
				document.body.classList.add('overflow-none');
			}else{
				document.body.classList.remove('overflow-none');
			}
		}
  	},
  	actions: {
  	},
  	modules: {
		auth,
		burses,
		tradersView,
		user,
		invest
  	}
})
