import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueTippy from 'vue-tippy';
import vue3GoogleLogin from 'vue3-google-login'

// Импорт стилей Tippy.js, если вы хотите использовать стандартные темы
import 'tippy.js/dist/tippy.css'; // Опционально

// Создание экземпляра приложения
const app = createApp(App);

app.use(vue3GoogleLogin, {
	clientId: '254815870808-6jl2dcagdrprpp2s1qcp4ao6hugq93jm.apps.googleusercontent.com'
});

// Использование VueTippy
app.use(VueTippy, {
	// Остальные глобальные настройки для Tippy
	directive: 'tippy', // Изменение директивы на v-tippy
	flipDuration: 0,
	popperOptions: {
	  modifiers: [{
		name: 'preventOverflow',
		options: {
		  enabled: false,
		},
	  }],
	},
	defaultProps: {
	  trigger: 'mouseenter click', // Добавляем это для активации тултипов при наведении и клике
	},
  });

// Регистрация router и store
app.use(router);
app.use(store);

// Монтирование приложения
app.mount('#app');
