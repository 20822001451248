export default function scrollTo(element) {
	const nodeObj = document.querySelector(element);

	if (!nodeObj)return;
	
	nodeObj.scrollIntoView({
		behavior: "smooth",
		block: "start"
	});
}
